import { createStore } from 'vuex';
import WS from '@/composables/webSocket.js';
import { getLocation, randomGeo } from '@/composables/maps.js';
import { getFriends } from '@/composables/user.js';
import { getNotificationsCount } from '@/composables/notifications.js';
import router from '@/router';

const notifIntervalValue = 30000;
const positionIntervalValue = 20000;

function setWS(store){
    if(!store.state.WS)
        store.state.WS = new WS(store);

    if(!store.state.WS.conn)
        store.state.WS.getWsTokenAndConnect();
}

function clearStoreInterval(state){
    if(state.positionInterval){
        clearInterval(state.positionInterval);
        state.positionInterval = false;
    }
    if(state.notifInterval){
        clearInterval(state.notifInterval);
        state.notifInterval = false;
    }
}


function initVisible(self){
    if(localStorage.getItem("visible_range")){
        self.state.visible.range = localStorage.getItem("visible_range");
    }
    else{
        self.state.visible.range = 1000;
        localStorage.setItem("visible_range", 1000);
    }
    self.commit("setVisible", localStorage.getItem("visible") ? localStorage.getItem("visible") : 0);
}

function yourLocation(state){
    getLocation((p)=>{
        if (p.coords){
            if(p.coords.latitude != state.visible.lat || p.coords.longitude !=state.visible.lng ){
                let range = parseInt(state.visible.range) || 1000;
                state.visible.coords = randomGeo(p.coords.latitude, p.coords.longitude, range);
                state.visible.lat = p.coords.latitude;
                state.visible.lng = p.coords.longitude;
                let tipo =  parseInt(state.visible.type) || 0;
                let data = JSON.stringify({t: "m", ta: "pos", lng: state.visible.coords.longitude, "lat": state.visible.coords.latitude, type: tipo});
                state.emitter.emit("sendmsg", data);
            }
            state.emitter.emit("mapmsg", {"username": -1, lat: p.coords.latitude, lng: p.coords.longitude});
        }
    });
}

function setNotifications(state){
    if(state.notifInterval){
        clearInterval(state.notifInterval);
    }
    getNotificationsCount((count)=>{
        state.notificaciones = count;
    });
    state.notifInterval = setInterval(()=>{
        getNotificationsCount((count)=>{
            state.notificaciones = count;
        });
    }, notifIntervalValue);
}

export default createStore({
    state: {
        token: false,
        WS: false,
        emitter: false,
        visible: {type:0, lat: false, lng: false, range: 0, coords:false},
        friends: [],
        user_data: {u: false, i: false, ic: false, d: false},
        cookies: false,
        notificaciones: [],
        timeCacheData: {},
        bannerHomeTime: false,
    },
    getters: {
        wsConnect(state){
            return state.WS.conn == 2;
        },
        wsState(state){
            if(state.WS.conn == 2)
                return 1;
            else if(state.WS.reconn < 0)
                return state.WS.reconn;
            else
                return 0;
        },
        getVisible(state){
            return state.visible;
        },
        visibleType(state){
            return state.visible.type;
        },
        isLogged(state){
            return state.token ? true : false;
        },
        hasCookies(state){
            return state.cookies ? true : false;
        },
        getFriends(state){
            return state.friends;
        },
        userData(state)
        {
            return state.user_data;
        },
        notificacionesPendientes(state)
        {
            return state.notificaciones;
        },
        getTimeCacheData: (state) => (key, maxTime = 600) =>
        {
            let now = new Date().getTime() / 1000;
            let keyData = state[key];
            if(keyData && keyData.data && keyData.time){
                if(!maxTime || ((keyData.time + maxTime) > now))
                    return keyData.data;
                else
                    keyData.splice(key, 1);
            }
            return false;
        },
        bannerHomeTimeVisible(state){
            let now = parseInt(new Date().getTime() / 1000);
            let timePlus = 3600 * 24;
            if(!state.bannerHomeTime || (now > (parseInt(state.bannerHomeTime) + timePlus))){
                localStorage.setItem('bannerHomeTime', now);
                return true;
            }
            return false;
        }
    },
    mutations: {
        login(state, obj){
            localStorage.setItem('token', obj.token);
            state.user_data.u = obj.username;
            state.user_data.i = obj.img;
            state.user_data.ic = obj.imgc;
            state.user_data.d = obj.d;
            setNotifications(state);
            localStorage.setItem('user_data', JSON.stringify(state.user_data));
            localStorage.setItem('finish_date', obj.date);
            let fin = obj.date - Math.round(new Date().getTime() / 1000);
            setTimeout(()=>{
                this.commit("logout");
            }, fin * 1000);
            getFriends((fr)=>{
                state.friends =fr;
            });
            state.token = obj.token;
            setWS(this);
            initVisible(this);
            state.emitter.emit("loginEvent");
        },
        logout: (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem("visible");
            localStorage.removeItem("visible_range");
            localStorage.removeItem("user_data");
            state.token = false;
            if(state.WS){
                state.WS.close();
            }
            clearStoreInterval(state);
            state.notificaciones = [];
            router.push("/");
        },
        initialStore(state, emitter){
            state.emitter = emitter;
            if(localStorage.getItem('cookies'))
                state.cookies = localStorage.getItem('cookies');
            if(localStorage.getItem('bannerHomeTime'))
                state.bannerHomeTime = localStorage.getItem('bannerHomeTime');
            if(localStorage.getItem('token')){
                let fin = localStorage.getItem('finish_date') - Math.round(new Date().getTime() / 1000);
                if(fin < 10){
                    this.commit("logout");
                    return;
                }
                try{
                    state.user_data = JSON.parse(localStorage.getItem('user_data'));
                }catch(e){
                    state.user_data = null;
                }
                setNotifications(state);
                getFriends((fr)=>{
                    state.friends =fr;
                });
                state.token = localStorage.getItem('token');

                setWS(this);
                setTimeout(()=>{
                    this.commit("logout");
                }, fin * 1000);
                initVisible(this);
            }
        },
        nfriend(state, data){
            if(data && data.ta && data.username){
                if(data.ta == "d"){
                    state.friends = state.friends.filter((user) => {
                        return user.username !== data.username
                    });
                }
                else if(data.ta == "a" && data.img){
                    state.friends.push({username: data.username, img: data.img});
                }
            }
        },
        setVisible(state, visible){
            localStorage.setItem('visible', visible);
            state.visible.type = visible;
            if(visible > 0){
                if(state.visible.lat && state.visible.lng){
                    state.visible.coords = randomGeo(state.visible.lat, state.visible.lng, state.visible.range);
                    let data = JSON.stringify({t: "m", ta: "pos", lng: state.visible.coords.longitude, "lat": state.visible.coords.latitude, type: visible});
                    state.emitter.emit("sendmsg", data);
                }else{
                    yourLocation(state);
                }
                if(!state.positionInterval){
                    state.positionInterval = setInterval(()=>{
                        yourLocation(state);
                    }, positionIntervalValue);
                }
            }else{
                let data = JSON.stringify({t: "m", ta: "pos", lng: 0, "lat": 0, type: 0});
                state.emitter.emit("sendmsg", data);
                if(state.positionInterval)
                    clearInterval(state.positionInterval);
            }
        },
        setVisibleRange(state, range){
            range = parseInt(range) || 1000;
            state.visible.range = range <= 0 ? 1 : range;
            localStorage.setItem("visible_range", range);
            let tipo =  parseInt(state.visible.type) || 0;
            if(tipo > 0 && state.visible.lat && state.visible.lng ){
                state.visible.coords = randomGeo(state.visible.lat, state.visible.lng, state.visible.range);
                let data = JSON.stringify({t: "m", ta: "pos", lng: state.visible.coords.longitude, "lat": state.visible.coords.latitude, type: tipo});
                state.emitter.emit("sendmsg", data);
            }
        },
        setCookies(state, value){
            if(value != 1 || value != 2){
                value = 2;
            }
            state.cookies = value;
            localStorage.setItem('cookies', value);
        },
        changePerfil(state, value){
            if(value.i && value.ic){
                state.user_data.i = value.i;
                state.user_data.ic = value.ic;
                state.user_data.d = value.d ? value.d : "";
                localStorage.setItem('user_data', JSON.stringify(state.user_data));
            }
        },
        seeNotifications(state)
        {
            state.notificaciones = 0;
        },
        cacheDataTime(state, key, data){
            let now = new Date().getTime() / 1000;
            state.timeCacheData[key] = {time: now, data: data};
        }
    },
    actions: {
    },
    modules: {
    }
});