<template>
    <div class="post_comments_wrapper">
        <div class="post_comments">
            <div class="post_user_comment">
                <div class="wall_item_user_photo">
                    <router-link :to="'/perfil/'+props.post.username" :title="props.post.username">
                        <img :src="props.post.userimg" :alt="props.post.username" width="34" height="34">
                    </router-link>
                </div>
                <div class="post_content">
                    <TextPost :text="props.post.mediatext" :username="props.post.username"/>
                </div>
            </div>
            <div class="post_guest">
                <ul>
                    <li v-for="c in props.comentarios" :key="c.id">
                        <div class="post_guest_comment">
                            <div class="wall_item_user_photo">
                                <router-link :to="'/perfil/'+c.username" :title="c.username">
                                    <img :src="c.userimg" :alt="c.username" width="34" height="34">
                                </router-link>
                            </div>
                            <div class="post_content">
                                <TextPost :text="c.comentario" :username="c.username" :activeHashtags="false"/>
                                <div class="post_content_actions">
                                    <span>{{c.date}}</span>
                                    <span>{{c.likes}} likes</span>
                                    <span>
                                        <button class="comment_answer_btn" @click="responseTo(c.id, c.username)">Responder</button>
                                    </span>
                                    <span>
                                        <button v-if="store.state.user_data.u === c.username" class="comment_answer_btn" @click="emit('deleteComment',{idp: false, idc: c.id})">Eliminar</button>
                                    </span>    
                                </div>
                                <div class="post_like_btn"  @click="()=>{emit('likeComentario', c.id)}">
                                    <div class="wall_item_like">
                                        <div class="wall_item_like_toggle">
                                            <span v-if="c.islike && c.islike == true"  class="like_full">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 22h-5v-12h5v12zm17.615-8.412c-.857-.115-.578-.734.031-.922.521-.16 1.354-.5 1.354-1.51 0-.672-.5-1.562-2.271-1.49-1.228.05-3.666-.198-4.979-.885.906-3.656.688-8.781-1.688-8.781-1.594 0-1.896 1.807-2.375 3.469-1.221 4.242-3.312 6.017-5.687 6.885v10.878c4.382.701 6.345 2.768 10.505 2.768 3.198 0 4.852-1.735 4.852-2.666 0-.335-.272-.573-.96-.626-.811-.062-.734-.812.031-.953 1.268-.234 1.826-.914 1.826-1.543 0-.529-.396-1.022-1.098-1.181-.837-.189-.664-.757.031-.812 1.133-.09 1.688-.764 1.688-1.41 0-.565-.424-1.109-1.26-1.221z"></path></svg>
                                            </span>
                                            <span v-else class="like_empty">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.406 9.558c-1.21-.051-2.87-.278-3.977-.744.809-3.283 1.253-8.814-2.196-8.814-1.861 0-2.351 1.668-2.833 3.329-1.548 5.336-3.946 6.816-6.4 7.401v-.73h-6v12h6v-.904c2.378.228 4.119.864 6.169 1.746 1.257.541 3.053 1.158 5.336 1.158 2.538 0 4.295-.997 5.009-3.686.5-1.877 1.486-7.25 1.486-8.25 0-1.648-1.168-2.446-2.594-2.506zm-17.406 10.442h-2v-8h2v8zm15.896-5.583s.201.01 1.069-.027c1.082-.046 1.051 1.469.004 1.563l-1.761.099c-.734.094-.656 1.203.141 1.172 0 0 .686-.017 1.143-.041 1.068-.056 1.016 1.429.04 1.551-.424.053-1.745.115-1.745.115-.811.072-.706 1.235.109 1.141l.771-.031c.822-.074 1.003.825-.292 1.661-1.567.881-4.685.131-6.416-.614-2.239-.965-4.438-1.934-6.959-2.006v-6c3.264-.749 6.328-2.254 8.321-9.113.898-3.092 1.679-1.931 1.679.574 0 2.071-.49 3.786-.921 5.533 1.061.543 3.371 1.402 6.12 1.556 1.055.059 1.024 1.455-.051 1.584l-1.394.167s-.608 1.111.142 1.116z"/></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="post_answer_block" v-if="c.items.length > 0">

                            <div @click="verRespuestas(c.id)" class="post_answer_toggle">Ver las respuestas ({{c.items.length}})</div>

                            <ul :id="`respuestas_${c.id}`" style="display:none;">
                                <li v-for="ch in c.items.slice().reverse()" :key="ch.id">

                                    <div class="post_guest_comment post_answer">
                                        <div class="wall_item_user_photo">
                                            <router-link :to="'/perfil/'+ch.username" :title="ch.username">
                                                <img :src="ch.userimg" :alt="ch.username" width="34" height="34">
                                            </router-link>
                                        </div>
                                        <div class="post_content">
                                            <TextPost :text="ch.comentario" :username="ch.username" :activeHashtags="false"/>
                                            <div class="post_content_actions">
                                                <span>{{ch.date}}</span>
                                                <span>{{ch.likes}} likes</span>
                                                <span>
                                                    <button class="comment_answer_btn" @click="responseTo(c.id, ch.username)">Responder</button>
                                                </span>
                                                <span>
                                                    <button v-if="store.state.user_data.u === ch.username" class="comment_answer_btn" @click="emit('deleteComment', {idp: c.id, idc: ch.id})">Eliminar</button>
                                                </span>
                                            </div>
                                            <div class="post_like_btn" @click="()=>{emit('likeComentario', ch.id)}">
                                                <div class="wall_item_like">
                                                    <div class="wall_item_like_toggle">
                                                        <span v-if="ch.islike && ch.islike == true"  class="like_full">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 22h-5v-12h5v12zm17.615-8.412c-.857-.115-.578-.734.031-.922.521-.16 1.354-.5 1.354-1.51 0-.672-.5-1.562-2.271-1.49-1.228.05-3.666-.198-4.979-.885.906-3.656.688-8.781-1.688-8.781-1.594 0-1.896 1.807-2.375 3.469-1.221 4.242-3.312 6.017-5.687 6.885v10.878c4.382.701 6.345 2.768 10.505 2.768 3.198 0 4.852-1.735 4.852-2.666 0-.335-.272-.573-.96-.626-.811-.062-.734-.812.031-.953 1.268-.234 1.826-.914 1.826-1.543 0-.529-.396-1.022-1.098-1.181-.837-.189-.664-.757.031-.812 1.133-.09 1.688-.764 1.688-1.41 0-.565-.424-1.109-1.26-1.221z"></path></svg>
                                                        </span>
                                                        <span v-else class="like_empty">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.406 9.558c-1.21-.051-2.87-.278-3.977-.744.809-3.283 1.253-8.814-2.196-8.814-1.861 0-2.351 1.668-2.833 3.329-1.548 5.336-3.946 6.816-6.4 7.401v-.73h-6v12h6v-.904c2.378.228 4.119.864 6.169 1.746 1.257.541 3.053 1.158 5.336 1.158 2.538 0 4.295-.997 5.009-3.686.5-1.877 1.486-7.25 1.486-8.25 0-1.648-1.168-2.446-2.594-2.506zm-17.406 10.442h-2v-8h2v8zm15.896-5.583s.201.01 1.069-.027c1.082-.046 1.051 1.469.004 1.563l-1.761.099c-.734.094-.656 1.203.141 1.172 0 0 .686-.017 1.143-.041 1.068-.056 1.016 1.429.04 1.551-.424.053-1.745.115-1.745.115-.811.072-.706 1.235.109 1.141l.771-.031c.822-.074 1.003.825-.292 1.661-1.567.881-4.685.131-6.416-.614-2.239-.965-4.438-1.934-6.959-2.006v-6c3.264-.749 6.328-2.254 8.321-9.113.898-3.092 1.679-1.931 1.679.574 0 2.071-.49 3.786-.921 5.533 1.061.543 3.371 1.402 6.12 1.556 1.055.059 1.024 1.455-.051 1.584l-1.394.167s-.608 1.111.142 1.116z"/></svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            
                        </div>

                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="post_window_info_footer">
        <div class="wall_item_actions">
            <div class="wall_item_like" @click="emit('likePost')">
                <div class="wall_item_like_toggle">
                    <span class="like_full" v-if="props.post.islike && props.post.islike == true" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 22h-5v-12h5v12zm17.615-8.412c-.857-.115-.578-.734.031-.922.521-.16 1.354-.5 1.354-1.51 0-.672-.5-1.562-2.271-1.49-1.228.05-3.666-.198-4.979-.885.906-3.656.688-8.781-1.688-8.781-1.594 0-1.896 1.807-2.375 3.469-1.221 4.242-3.312 6.017-5.687 6.885v10.878c4.382.701 6.345 2.768 10.505 2.768 3.198 0 4.852-1.735 4.852-2.666 0-.335-.272-.573-.96-.626-.811-.062-.734-.812.031-.953 1.268-.234 1.826-.914 1.826-1.543 0-.529-.396-1.022-1.098-1.181-.837-.189-.664-.757.031-.812 1.133-.09 1.688-.764 1.688-1.41 0-.565-.424-1.109-1.26-1.221z"></path></svg>
                    </span>
                    <span class="like_empty">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.406 9.558c-1.21-.051-2.87-.278-3.977-.744.809-3.283 1.253-8.814-2.196-8.814-1.861 0-2.351 1.668-2.833 3.329-1.548 5.336-3.946 6.816-6.4 7.401v-.73h-6v12h6v-.904c2.378.228 4.119.864 6.169 1.746 1.257.541 3.053 1.158 5.336 1.158 2.538 0 4.295-.997 5.009-3.686.5-1.877 1.486-7.25 1.486-8.25 0-1.648-1.168-2.446-2.594-2.506zm-17.406 10.442h-2v-8h2v8zm15.896-5.583s.201.01 1.069-.027c1.082-.046 1.051 1.469.004 1.563l-1.761.099c-.734.094-.656 1.203.141 1.172 0 0 .686-.017 1.143-.041 1.068-.056 1.016 1.429.04 1.551-.424.053-1.745.115-1.745.115-.811.072-.706 1.235.109 1.141l.771-.031c.822-.074 1.003.825-.292 1.661-1.567.881-4.685.131-6.416-.614-2.239-.965-4.438-1.934-6.959-2.006v-6c3.264-.749 6.328-2.254 8.321-9.113.898-3.092 1.679-1.931 1.679.574 0 2.071-.49 3.786-.921 5.533 1.061.543 3.371 1.402 6.12 1.556 1.055.059 1.024 1.455-.051 1.584l-1.394.167s-.608 1.111.142 1.116z"/></svg>
                    </span>
                    <b>{{props.post.likes}}</b>
                </div>
            </div>
        </div>
        <div class="post_message_block">
            <EmojiSelector :classTheme="'emoji_dark'" @emojiSelect="(e)=>{ comentarioText.value += e }"/>
            <textarea ref="comentarioText" placeholder="Comentario"></textarea>
            <button @click="sendComentario()">Enviar</button>
        </div>
    </div>
</template> 
<script setup>
    import { ref } from 'vue';
    import TextPost from '@/components/TextPost.vue';
    import EmojiSelector from '@/components/EmojiSelector.vue';
    import { useStore } from 'vuex';
    
    const comentarioText = ref("");
    const store = useStore();
    const emit = defineEmits(["comment", "likeComentario", "likePost", "deleteComment"])
    const responseComment = ref(0);
    const props = defineProps({
        post: {
            type: Object
        },
        comentarios: {
            type: Array
        }
    });

    const verRespuestas = (id) => {
        let elem = document.getElementById(`respuestas_${id}`);
        if(elem.style.display == "block"){
            elem.style.display = "none";
        }else{
            elem.style.display = "block";
        }
    }

    const responseTo = (index, user) => {
        responseComment.value = index;
        comentarioText.value.value = `@${user} `;
    }

    const sendComentario = () => {
        if(/\S/.test(comentarioText.value.value)){
            emit("comment", {c: comentarioText.value.value, p: responseComment.value, u: props.post.url});
            comentarioText.value.value = "";
            responseComment.value = 0;
        }
    }

    // const addHeight = (element) => {
    //     element.style.height = "5px";
    //     element.style.height = (element.scrollHeight) + "px";
    // }



 
</script>

<style scoped>

/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

WALL

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*----------------------------------------------------------------------------------- 
GRID WALL
-----------------------------------------------------------------------------------*/
.grid_wall {position: inherit;}
.grid_wall .grid_wall_item {background-color: #000;cursor: pointer;position: relative;overflow: hidden;;
    border-radius: 7px;
}
.grid_wall .grid_wall_item .grid_wall_item_img {padding-top: 100%;overflow: hidden;}
.grid_wall .grid_wall_item .grid_wall_item_img img {width: 100%;height: 100%;position: absolute;top: 0;right: 0;bottom: 0;left: 0;object-fit: cover;z-index: 1;
     opacity: 1;
}
.grid_wall .grid_wall_item .grid_wall_item_img .grid_wall_item_edit {background-color: rgba(0,0,0,0.3);position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 2;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.grid_wall .grid_wall_item:hover .grid_wall_item_img .grid_wall_item_edit {display: flex;}
.grid_wall .grid_wall_item .grid_wall_item_img .grid_wall_item_edit .grid_wall_item_btn {width: 30px;height: 30px;
    opacity: 0.7;
}
.grid_wall .grid_wall_item .grid_wall_item_img .grid_wall_item_edit .grid_wall_item_btn:hover {
    opacity: 1;
}
.grid_wall .grid_wall_item .grid_wall_item_img .grid_wall_item_edit .grid_wall_item_btn svg {fill: #fff;}
.grid_wall.grid__motor.grid__motor_col_1, .grid_wall.grid__motor.grid__motor_col_2, .grid_wall.grid__motor.grid__motor_col_3, .grid_wall.grid__motor.grid__motor_col_4, .grid_wall.grid__motor.grid__motor_col_5 {
    grid-gap: 5px 5px;
}

/*----------------------------------------------------------------------------------- 
VERTICAL WALL
-----------------------------------------------------------------------------------*/
.vertical_wall {width: 100%}
.vertical_wall .wall_item {background-color: #fff;margin-top: 30px;
	border-radius: 7px;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.5);
box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.5);
}
.vertical_wall .wall_item:first-child {margin-top: 0;}
.vertical_wall .wall_item .wall_item_header {padding: 10px;
	display: flex;
	align-items: center;
}
.vertical_wall .wall_item .wall_item_header .wall_item_user_photo {width: 35px;margin-right: 10px;}
.vertical_wall .wall_item .wall_item_header .wall_item_user_photo img {border: solid 1px #cacaca;width: 35px;height: 35px;
	border-radius: 30px;
}
.vertical_wall .wall_item .wall_item_header .wall_item_user_photo:hover img {border-color: #333;}
.vertical_wall .wall_item .wall_item_header .wall_item_user {font-size: 13px;font-family: 'Open sans', sans-serif;}
.vertical_wall .wall_item .wall_item_header .wall_item_user a {font-weight: bold;}
.vertical_wall .wall_item .wall_item_header .wall_item_user span {color: #989898;}
.vertical_wall .wall_item .wall_item_menu {cursor: pointer;margin-left: auto;position: relative;width: 30px;height: 30px;
	display: flex;
}
.vertical_wall .wall_item .wall_item_menu_trigger{width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vertical_wall .wall_item .wall_item_menu_trigger svg {width: 18px;height: 18px;} 
.vertical_wall .wall_item .wall_item_menu_trigger:hover, .vertical_wall .wall_item .wall_item_menu_trigger.active, .vertical_wall .wall_item .wall_item_menu_trigger.active  {background-color: #e5e5e5;
	border-radius: 30px;
} 
.wall_item_menu_list {background-color: #fff;position: absolute;top: 30px;right: 5px;width: 120px;z-index: 10;overflow: hidden;
	border-radius: 5px;
	-webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.70);
	-moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.70);
	box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.70);
}
.wall_item_menu_btn {border-top: solid 1px #e7e7e7;cursor: pointer;font-size:13px;padding:5px 10px;text-align: center;}
.wall_item_menu_btn::first-child {border: none;}
.wall_item_menu_btn:hover {background-color: #e5e5e5;}
.wall_item_text {padding: 15px;}
.wall_item_text p {margin-bottom: 0;}
.wall_item_actions {margin-bottom: 20px;}
.post_comments_link  {cursor: pointer;font-size: 12px;}
.post_comments_link:hover  {text-decoration: underline;}
.post_comment_field input {border-width: 0 0 2px 0;font-size: 12px;padding: 10px 0;
    border-radius: 0;
}
.wall_item_like {
	display: flex;
}
.wall_item_like .wall_item_like_toggle {cursor: pointer;position: relative;height: 16px;overflow: hidden;padding-left: 25px;}
.wall_item_like .wall_item_like_toggle span {position: absolute;left: 0;top: 0;}
.wall_item_like .wall_item_like_toggle b {font-size: 16px;line-height: 16px;}
.wall_item_like .wall_item_like_toggle span svg {fill: #6b6b6b;width: 16px;height: 16px;}
.wall_item_like .wall_item_like_toggle:hover span svg {fill: #000;}
.wall_item_like .wall_item_like_toggle span.like_empty {visibility: visible;z-index: 2;}
.wall_item_like .wall_item_like_toggle span.like_full {visibility: visible;z-index: 1;}

/*----------------------------------------------------------------------------------- 
POST WINDOW
-----------------------------------------------------------------------------------*/
.post_window {width: 100%}
.post_wrapper {
    display: flex;
    flex-direction: column;
}
.post_window .post_item_content {background-color: #000;max-width: 900px;min-height: 400px;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    flex-shrink: 1;
    justify-content: center;
}
.post_window .post_item_content .post_window_info_header {background-color: #fff;border-bottom: solid 1px #e5e5e5;padding: 10px;
    display: flex;
    align-items: center;
}
.post_window .post_item_content .post_window_info_header .wall_item_user {font-size: 12px;}
.post_window .post_item_content .post_window_info_header .wall_item_user a {font-weight: 700;}
.post_window .post_item_content .post_window_info_header .wall_item_user span {color: #989898;}
.post_window .post_item_content .post_item_media {max-height: 40vh;}
.post_window .post_item_media img {height: 100%;}
.user_awards {
	display: flex;
	gap: 5px;
	flex-direction: row;
}
.post_window .post_window_info {background-color: #fff;
	border-radius: 7px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 2;
    align-items: stretch;
}
.post_window .wall_item:first-child {margin-top: 0;}
.post_window .post_window_info .wall_item_user_photo {width: 35px;margin-right: 10px;}
.post_window .post_window_info .wall_item_user_photo img {border: solid 1px #cacaca;width: 35px;height: 35px;
	border-radius: 30px;
}
.post_window .post_window_info .wall_item_user_photo:hover img {border-color: #333;}
.post_window .post_window_info .post_window_info_header {border-bottom: solid 1px #e5e5e5;padding: 10px;
    display: none;
}
.post_window .post_window_info .post_window_info_header .wall_item_user {font-family: 'Open sans', sans-serif;font-size: 13px;}
.post_window .post_window_info .post_window_info_header .wall_item_user a {font-weight: bold;}
.post_window .post_window_info .post_window_info_header .wall_item_user span {color: #989898;}

.post_window .post_window_info .post_window_info_text {
    display: none;
}
.post_window .post_window_info .post_window_info_text .post_user_comment {
    display: flex;
    order: 1;
}
.post_window .post_window_info .post_comments_wrapper {padding: 0 10px;position: relative;height: 150px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
    overflow: auto;
    overflow-x: hidden;
    order: 1;
}
.post_window .post_window_info .post_comments_wrapper .post_comments {border: 0;width: calc(100% - 20px);
    box-sizing: content-box;
    flex-grow: 1;
    font: inherit;
    font-size: 100%;
    height: calc(100% - 32px);
    left: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px 10px;
    position: absolute;
    scrollbar-width: none;
    vertical-align: baseline;
}
.post_window .post_window_info .post_window_info_footer {border-top: solid 1px #e5e5e5;
    order: 2;
}
.post_window .post_window_info .post_window_info_footer .wall_item_actions {padding: 10px;margin-bottom: 0;}
.post_window .post_window_info .post_window_info_footer .post_message_block {border-top: solid 1px #e5e5e5;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
}
.post_window .post_window_info .post_window_info_footer .post_message_block textarea {border: none;padding: 0;margin: 0;font-size: 13px;line-height: 18px;
    border-radius: 0;
    min-height: 18px;
    max-height: 80px;
    resize: none;
    flex-grow: 1;
    -webkit-appearance: none;
}
.post_window .post_window_info .post_window_info_footer .post_message_block textarea::placeholder {
    line-height: 36px;
}
.post_window .post_window_info .post_window_info_footer .post_message_block button {color: #adadad;cursor: pointer;background: none;border: none;font-weight: bold;padding: 10px;}
.post_window .post_window_info .post_window_info_footer .post_message_block button:hover {color: #faa920;cursor: pointer;}
.post_window .post_window_info .post_guest {margin-top: 20px;}
.post_window .post_window_info .post_guest .post_guest_comment {margin-bottom: 20px;width: 100%;position: relative;
    display: flex;
}
.post_window .post_window_info .post_guest .post_guest_comment .post_content {padding-right: 35px;
    display: flex;
    flex-direction: column;
}
.post_window .post_window_info .post_guest .post_guest_comment .post_content .post_like_btn {position: absolute;right: 0;top: 5px;width: 12px;}
.post_window .post_window_info .post_guest .post_guest_comment .post_content .post_like_btn .wall_item_like .wall_item_like_toggle {width: 12px;}
.post_window .post_window_info .post_guest .post_guest_comment .post_content .post_like_btn svg {width: 12px;height: 12px;}
.post_window .post_window_info .post_guest .post_guest_comment .post_content .post_content_actions {font-size: 12px;margin-top: 10px;}
.post_window .post_window_info .post_guest .post_guest_comment .post_content .post_content_actions span {color: #979797;margin-right: 10px;}
.post_window .post_window_info .post_guest .post_guest_comment .post_content .post_content_actions .comment_answer_btn {color: #979797;cursor: pointer;background: none;font-size: 12px;}
.post_window .post_window_info .post_guest .post_guest_comment .post_content .post_content_actions .comment_answer_btn:hover {color: #000;text-decoration: underline;}
.post_window .post_window_info .post_guest .post_answer_block {margin-left: 40px;}
.post_window .post_window_info .post_guest .post_answer_toggle {cursor: pointer;color: #979797;font-size: 12px;padding-left: 40px;margin-bottom: 20px;position: relative;}
.post_window .post_window_info .post_guest .post_answer_toggle:hover {color: #000;text-decoration: underline;}
.post_window .post_window_info .post_guest .post_answer_toggle::before {content: '';background-color: #333;height: 1px;width: 30px;position: absolute;top: 8px;left: 0;}
.post_window .post_window_info .post_window_info_header .wall_item_user_photo:hover img {border-color: #333;}
.post_window .wall_item_menu {cursor: pointer;margin-left: auto;position: relative;width: 30px;height: 30px;
    display: flex;
}
.post_window .wall_item_menu_trigger{width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.post_window .wall_item_menu_trigger svg {width: 18px;height: 18px;} 
.post_window .wall_item_menu_trigger:hover, .post_window .wall_item_menu_trigger.active, .post_window .wall_item_menu_trigger.active  {background-color: #e5e5e5;
	border-radius: 30px;
}
.post_window .post_window_info_header .wall_item_user_photo {width: 35px;margin-right: 10px;}
.post_window .post_window_info_header .wall_item_user_photo img {border: solid 1px #cacaca;width: 35px;height: 35px;
	border-radius: 30px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #dbdbdb;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #dbdbdb;
}
*::-webkit-scrollbar-thumb {
  background-color: #919191;
  border-radius: 20px;
  border: 3px solid #dbdbdb;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #8b8b8b;
  border: 3px solid #dbdbdb;
}

@media (min-width: 765px) {
.post_wrapper {
    flex-direction: row;
}
.post_window .post_window_info {min-width: 400px;max-width: 500px;}
.post_window .post_window_info .post_window_info_header {
    display: flex;
    align-items: center;
}
.post_window .post_window_info .post_window_info_text {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.post_window .post_item_content .post_window_info_header {
    display: none;
}

}

</style>