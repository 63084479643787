<template>
    <div class="init_message" v-if="!store.getters.hasCookies">
      <div class="init_message_wrapper">
        <div class="message_content">
          <div class="message_content_header">nomadas.tv usa cookies</div>
          <p>Usamos cookies propias y de terceros para mejorar nuestros servicios y que tu navegación sea más segura, nunca comercializamos con tus datos, nos interesan tus preferencias para el análisis de tus hábitos de navegación. Si continúas navegando en esta página, consideramos que aceptas su uso, aún así podrías cambiar los términos indicados en la Política de Cookies.</p>
          <h4>Cookies imprescindibles</h4>
          <p>Estas cookies son obligatorias para poder disfrutar de las características que ofrece nomadas.tv. Son necesarias para poder tener una experiencia completa del modo previsto.</p>
          <h4>Cookies opcionales</h4>
          <p>En nomadas.tv usamos una herramienta de otra empresa como método de medición fuera de los productos de nomadas.tv, con fines de análisis de uso de la web y para mejorar la experiencia de usuario.</p>
          
          <router-link to="/legal" class="mb10" title="cookies" target="_blank" style="font-size: 13px;">Términos legales</router-link>
        </div>
        <div class="message_buttons">
          <div class="btn small grey wide mb10" style="background-color: #e7e7e7!important;color: #b5b5b5;" @click="aceptCookies(1)">Permito cookies imprescindibles</div>
          <div class="btn small grey wide" style="background-color: #06c18a!important;" @click="aceptCookies(2)">Permito cookies imprescindibles y opcionales</div>
        </div>
      </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { useStore } from 'vuex';
    const store = useStore();

    onMounted(()=>{ 
        if(store.getters.hasCookies && store.cookies == 2){
            setAnalytics();
        }
    });

    const aceptCookies = (value) => {
        store.commit("setCookies", value);
        if(value == 2){
            setAnalytics();
        }
    }

    const setAnalytics = () => {
        let recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4GLZPTFNNC');
        document.head.appendChild(recaptchaScript);
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-4GLZPTFNNC');
    }

 
</script>

<style scoped>
/*----------------------------------------------------------------------------------- 
INIT MESSAGE
-----------------------------------------------------------------------------------*/
.init_message {background: rgba(0,0,0,0.6);position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.init_message_wrapper {background: #fff;max-width: 600px;overflow: hidden;padding: 20px;margin: 20px;/*max-height: calc(100vh - 40px);*/
  border-radius: 10px;
}
.init_message .message_content {color: #000;font-size: 1em;overflow-x: auto;max-height: calc(100vh - 200px);
	display: -webkit-box;
  display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}
.init_message .message_buttons {padding: 20px 0 0 0;}
.init_message .message_content_header {color: #000;font-size: 1.5em;font-weight: 700;margin-bottom: 20px;}
.init_message .toggle_list {width: 100%;margin-bottom: 20px;
  display: flex;
}
.init_message .toggle_list .toggle {border: solid 1px #cacaca;}
.init_message .toggle_list .toggle.active {border-color: #666;}
.init_message .toggle_list .toggle p {color: #959595;margin-bottom: 0;}
.init_message .toggle_list .toggle.active p {color: #333;}
.init_message .message_content p {margin-bottom: 20px;padding:0;}
.init_message .message_content h4 {color: #333;font-size: 1em;font-weight: 700;margin-bottom: 10px;}
.init_message .message_content a {color: #000;display: inline-block;text-decoration: underline;}
.init_message .close_btn {background: #000;color: #006dff;cursor: pointer;display: block;text-align: center;font-weight: 700;padding: 8px 15px;}
.init_message .close_btn:hover {background: #06c18a;color: #fff;}
.init_message .close_btn svg {fill: #27b190;height: 24px;width: 24px;}

@media (min-width: 765px) {
    .init_message {}
}
</style>