import axios from 'axios';


function getNotifications(cb){
    let token = localStorage.getItem('token');
    if(token){
        axios.get(process.env.VUE_APP_URL+'notifications', {headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == "OK"){
                cb(rs.data);
            }
        });
    }
}

function getNotificationsCount(cb){
    let token = localStorage.getItem('token');
    if(token){
        axios.get(process.env.VUE_APP_URL+'notifications_nv', {headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == "OK"){
                cb(rs.data);
            }
        });
    }
}

function viewNotifications(cb=false){
    let token = localStorage.getItem('token');
    if(token){
        axios.post(process.env.VUE_APP_URL+'notifications', {}, {headers: {"API-TOKEN": token}})
        .then(response => {
            if(cb)
                cb(true);
        });
    }
}


export { getNotifications, getNotificationsCount, viewNotifications };