import axios from 'axios';

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio","Julio", "Augosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

function seeRobotsChats(){
    let token = localStorage.getItem('token');
    if(token){
        axios.get(`${process.env.VUE_APP_URL}view/nchats`, {headers: {"API-TOKEN": token}});
    }
}

function dateToChatDateTimeStr(date, mode=1)
{
    if (! (date instanceof Date)){
        date = new Date(date);
    }
    if ( mode == 1){
        let hours = (date.getHours() >= 10) ? date.getHours() : '0'+date.getHours();
        let minutes = (date.getMinutes() >= 10) ? date.getMinutes() : '0'+date.getMinutes();
        return hours + ":" + minutes;
    }
    else{
        return  date.getDate() + " de "+ monthNames[date.getMonth()];
    }
}

export { dateToChatDateTimeStr, seeRobotsChats };