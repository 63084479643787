/* *
 * conn false, 1 (connecting), 2(connected)
 * */
import axios from 'axios';

class WS {

    constructor(store){
        this.socket=false;
        this.conn=false;
        this.cola = [];
        this.emitter = store.state.emitter;
        this.store = store;
        this.reconn = 0;
        this.setEmitters();
    }

    connectWS(payload, token){
        this.conn=1;
        let url = process.env.VUE_APP_CHAT_URL+"?d="+payload+"&t="+token;
        this.socket = new WebSocket(url);
        this.socket.addEventListener('message', (event) => {
            let msg = JSON.parse(event.data);
            if(msg.t == "c"){
                this.emitter.emit("chatmsg", msg);
            }
            else if(msg.t == "m"){
                this.emitter.emit("mapmsg", msg);
            }
            else if(msg.t == "fr"){
                this.store.commit("nfriend", {"ta": msg.ta, "username": msg.u, "img": msg.i});
            }
        });
        
    
        this.socket.addEventListener('open', () => {
            this.conn=2;
            this.reconn = 0;
            while(this.cola.length > 0){
                this.socket.send(this.cola.pop());
            }
        });
    
        this.socket.addEventListener('error', () => {
        });
    
        this.socket.addEventListener('close', (m) => {
            this.socket=false;
            this.conn = false;
            this.cola = [];
            if(m.code == 3002){
                this.reconn = -1;
            }else if(m.code == 3000){
                this.emitter.emit("mapmsg", {ta: "o_pest"});
                this.reconn = -2;
            }
            else{
                this.tryReconnect();
            }
        });
    }

    close(){
        this.reconn = -1;
        if(this.socket){
            this.socket.close();
        }
    }
    
    /* antes solo en cola si conn=1 */
    setEmitters(){
        this.emitter.on("sendmsg", (m) => {
            let mensaje = btoa(unescape(encodeURIComponent(m)));
            if(this.socket && this.conn === 2){
                this.socket.send(mensaje);
            }
            else{
                this.cola.push(mensaje);
            }
        });
    }

    getWsTokenAndConnect(){
        let token = localStorage.getItem("token");
        if(!token){
            this.reconn = -1;
        }
        axios.post(process.env.VUE_APP_URL+'connectws', {}, {headers:{"API-TOKEN": token}})
        .then(response => {
            let rs = response.data;
            if(rs.state == 'OK'){
                this.connectWS(rs.data.data, rs.data.token);
            }
        });
    }

    tryReconnect(){
        if(this.reconn >= 0 && this.reconn < 5){
            this.reconn++;
            setTimeout(()=>{
                this.getWsTokenAndConnect();
            }, 5000*this.reconn);
        }else{
            this.reconn =-1;
        }
    }
}


export default WS;

