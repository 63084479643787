import axios from 'axios';

let followInterval = false;

function followUser(store, username, emitter, cb){
    if(followInterval)
        clearTimeout(followInterval);
    
    followInterval = setTimeout(()=>{
        if(store.state.token){
            axios.post(process.env.VUE_APP_URL+'follow/user/'+ username, {}, {headers: {"API-TOKEN": store.state.token}})
            .then((response) => {
                var rs = response.data;
                if(rs.state == "OK"){
                    if(rs.wsm){
                        store.commit("nfriend", {username: username, img: rs.wsm.i, ta: rs.wsm.ta});
                        if(emitter){
                            emitter.emit("sendmsg", JSON.stringify({"t":"au", data: rs.wsm.data, token: rs.wsm.token}));
                        }
                    }
                    if(cb)
                        cb(true);
                }
                return true;
            })
        }
        return false;
    }, 500);
}


function perfilData(username, cb, type=1){
    let token = localStorage.getItem('token');
    if(token){
        axios.get(process.env.VUE_APP_URL+'perfildata/'+ username, {headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == "OK"){
                let img = rs.data.img;
                if(type == 2){
                    let imgA = img.split(".");
                    img = imgA.slice(0, imgA.length-1).join(".");
                    img = img + "_40." +imgA[imgA.length-1];
                }
                cb(img, rs.data.descr);
            }
        });
    }
    return false;
}

function logout(store){ 
    if(!store.state.token)
        return;
    else{
        let token = store.state.token;
        store.commit('logout');
        axios.post(process.env.VUE_APP_URL+`logout`,{} ,{headers: {"API-TOKEN": token}});
    }
}

function getFriends(cb){
    let token = localStorage.getItem('token');
    if(token){
        axios.get(process.env.VUE_APP_URL+'friends', {headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == 'OK'){
                cb(rs.data);
            }
        });
    }
    return false;
}

function getFollowers(username, cb){
    let token = localStorage.getItem('token');
    let headers = {};
    if(token) headers = {"API-TOKEN": token};
    axios.get(`${process.env.VUE_APP_URL}followers/${username}`, {headers: headers})
    .then(response => {
        var rs = response.data;
        if(rs.state == 'OK'){
            cb(rs.data);
        }
    });
    return false;
}

function getFollowed(username, cb){
    let token = localStorage.getItem('token');
    let headers = {};
    if(token) headers = {"API-TOKEN": token};
    axios.get(`${process.env.VUE_APP_URL}followed/${username}`, {headers: headers})
    .then(response => {
        var rs = response.data;
        if(rs.state == 'OK'){
            cb(rs.data);
        }
    });
    return false;
}

function getAllFollows(username, cb){
    let token = localStorage.getItem('token');
    let headers = {};
    if(token) headers = {"API-TOKEN": token};
    axios.get(`${process.env.VUE_APP_URL}follows/${username}`, {headers: headers})
    .then(response => {
        var rs = response.data;
        if(rs.state == 'OK'){
            cb(rs.data);
        }
    });
    return false;
}

function quitFollower(username, cb){
    let token = localStorage.getItem('token');
    if(token){
        axios.post(`${process.env.VUE_APP_URL}quitfollow/${username}`, {},{headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == 'OK'){
                cb(true);
            }
        });
    }
}


export {followUser, perfilData, logout, getFriends, getFollowers, getFollowed, getAllFollows, quitFollower};