<template>
    <header class="header chat_active">
        <div class="menu">
            <div @click="ocmenu()" class="menu_trigger" id="trigger">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="logo">
                <router-link to="/"><img src="@/assets/images/nomadas_tv_logo.png" alt="nomadas.tv"></router-link>
            </div>
            <nav class="navigation">
                <div class="navigation_group left">
                    <div class="navigation_item">
                        <router-link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"/></svg></router-link>
                    </div>
                    <div class="navigation_item">
                        <router-link to="/mapa"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 16l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 4-9 3.96-1.584c.38.516.741 1.08 1.061 1.729l-3.523 1.41-1.725 3.88 2.672-1.01 1.506-2.687-.635 3.044 4.189 1.789.495-2.021.465 2.024 4.15-1.89-.618-3.033 1.572 2.896 2.732.989-1.739-3.978-3.581-1.415c.319-.65.681-1.215 1.062-1.731l4.021 1.588 3.936 9z"/></svg><span>Mapa</span>
                        </router-link>
                    </div>
                    <div class="navigation_item">
                        <router-link to="/canales"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4 5v18h20v-18h-20zm4 16h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm10 12h-8v-6h8v6zm0-8h-8v-6h8v6zm4 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm-1-6h-19v17h-2v-19h21v2z"/></svg><span>Canales</span></router-link>
                    </div>
                    <div class="navigation_item">
                        <router-link to="/muro"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M10 18v5h-10v-5h10zm10 0v5h-9v-5h9zm4-6v5h-7v-5h7zm-17 0v5h-7v-5h7zm9 0v5h-8v-5h8zm-6-6v5h-10v-5h10zm10 0v5h-9v-5h9zm-13-5v4h-7v-4h7zm8 0v4h-7v-4h7z"/></svg><span>Muro</span></router-link>
                    </div>
                </div>
                <div class="navigation_group center">
                    <MySuggester ref="searcher" :typeStyle="1" :search="false" @clear="() => searchItems = []" :dropdown="searchDropDown" :items="searchItems" :placeholder="'Búsqueda'" @onInput="getSearch" @selectItem="selectItem" />
                </div>
                <div class="navigation_group right">

                    <div class="navigation_item">
                        <button @click="openChatEmit(store, emitter)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" data-v-072be274=""><path d="M20 9.352c0-4.852-4.75-8.352-10-8.352-5.281 0-10 3.527-10 8.352 0 1.71.615 3.39 1.705 4.695.047 1.527-.85 3.719-1.66 5.312 2.168-.391 5.252-1.258 6.648-2.115 7.698 1.877 13.307-2.842 13.307-7.892zm-14.5 1.381c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm7.036 1.441c-.161.488-.361.961-.601 1.416 1.677 1.262 2.257 3.226.464 5.365-.021.745-.049 1.049.138 1.865-.892-.307-.979-.392-1.665-.813-2.127.519-4.265.696-6.089-.855-.562.159-1.145.278-1.74.364 1.513 1.877 4.298 2.897 7.577 2.1.914.561 2.933 1.127 4.352 1.385-.53-1.045-1.117-2.479-1.088-3.479 1.755-2.098 1.543-5.436-1.348-7.348z" data-v-072be274=""></path></svg>
                            <span>Chat</span>
                        </button>
                    </div>
                    <div class="navigation_item">
                        <a href="https://areaprofesionales.nomadas.tv" target="_blank" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M756-120 537-339l84-84 219 219-84 84Zm-552 0-84-84 276-276-68-68-28 28-51-51v82l-28 28-121-121 28-28h82l-50-50 142-142q20-20 43-29t47-9q24 0 47 9t43 29l-92 92 50 50-28 28 68 68 90-90q-4-11-6.5-23t-2.5-24q0-59 40.5-99.5T701-841q15 0 28.5 3t27.5 9l-99 99 72 72 99-99q7 14 9.5 27.5T841-701q0 59-40.5 99.5T701-561q-12 0-24-2t-23-7L204-120Z"/></svg>
                            <span>Area de profesionales</span>
                        </a>
                    </div>
                    <div class="navigation_item">
                        <router-link to="/contacto">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"/></svg><span>Contacto</span>
                        </router-link>
                    </div>
                    <div class="navigation_item btn_list_trigger">
                        <div class="btn_list_btn" v-if="!isloged()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-3.123 0-5.914-1.441-7.749-3.69.259-.588.783-.995 1.867-1.246 2.244-.518 4.459-.981 3.393-2.945-3.155-5.82-.899-9.119 2.489-9.119 3.322 0 5.634 3.177 2.489 9.119-1.035 1.952 1.1 2.416 3.393 2.945 1.082.25 1.61.655 1.871 1.241-1.836 2.253-4.628 3.695-7.753 3.695z"/></svg><span>Mi cuenta</span></div>
                        <div class="btn_list">
                            <button @click="()=>{ emitter.emit('popUpLogin', 1); }" v-if="!isloged()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.451 17.337l-2.451 2.663h-2v2h-2v2h-6v-1.293l7.06-7.06c-.214-.26-.413-.533-.599-.815l-6.461 6.461v-2.293l6.865-6.949c1.08 2.424 3.095 4.336 5.586 5.286zm11.549-9.337c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-3-3c0-1.104-.896-2-2-2s-2 .896-2 2 .896 2 2 2 2-.896 2-2z"/></svg><span>Login</span></button>
                            <button @click="()=>{ emitter.emit('popUpLogin', 2); }" v-if="!isloged()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.963 8.261c-.566-.585-.536-1.503.047-2.07l5.948-5.768c.291-.281.664-.423 1.035-.423.376 0 .75.146 1.035.44l-8.065 7.821zm-9.778 14.696c-.123.118-.185.277-.185.436 0 .333.271.607.607.607.152 0 .305-.057.423-.171l.999-.972-.845-.872-.999.972zm8.44-11.234l-3.419 3.314c-1.837 1.781-2.774 3.507-3.64 5.916l1.509 1.559c2.434-.79 4.187-1.673 6.024-3.455l3.418-3.315-3.892-4.019zm9.97-10.212l-8.806 8.54 4.436 4.579 8.806-8.538c.645-.626.969-1.458.969-2.291 0-2.784-3.373-4.261-5.405-2.29z"/></svg><span>Registro</span></button>
                        </div>
                    </div>
                    <div class="navigation_item" v-if="isloged()" >
                        <router-link to="/perfil">
                            <div class="notification_baloon" v-if="store.getters.notificacionesPendientes > 0">
                                {{store.getters.notificacionesPendientes}}
                            </div>
                            <div class="user_profile_photo">
                                <img :src="store.state.user_data.i">
                            </div>
                            <span>{{store.state.user_data.u}}</span>
                        </router-link>
                    </div>
                    <div class="navigation_item" v-if="isloged()" >
                        <button @click="logout(store)"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 10v-5l8 7-8 7v-5h-8v-4h8zm-16-8v20h14v-2h-12v-16h12v-2h-14z"/></svg></button>
                    </div>
                    <!-----
                    <div class="navigation_item chat_nav_item">
                        <button><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 9.352c0-4.852-4.751-8.352-10-8.352-5.281 0-10 3.526-10 8.352 0 1.711.615 3.391 1.705 4.695.047 1.527-.851 3.718-1.661 5.312 2.168-.391 5.252-1.258 6.649-2.115 7.697 1.877 13.307-2.842 13.307-7.892zm-14.5 1.38c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm8.383 8.789c-.029 1.001.558 2.435 1.088 3.479-1.419-.258-3.438-.824-4.352-1.385-3.365.818-6.114-.29-7.573-2.1 4.557-.66 8.241-3.557 9.489-7.342 1.48.979 2.465 2.491 2.465 4.274 0 1.12-.403 2.221-1.117 3.074z"/></svg><span>Chatea</span></button>
                    </div>--->
                </div>
            </nav>
        </div>
        <div id="navegacion" class="dynamic__container container__left mobile_nav_wrapper">
            <div class="mobile_nav">
                <div class="mobile_nav_group">
                    <div class="navigation_item">
                        <router-link @click="ocmenu()" to="/canales">Canales</router-link>
                    </div>
                    <div class="navigation_item">
                        <router-link @click="ocmenu()" to="/mapa">Mapa</router-link>
                    </div>
                    <div class="navigation_item">
                        <router-link @click="ocmenu()" to="/muro">Muro</router-link>
                    </div>
                </div>
                <div class="mobile_nav_separator"></div>
                <div class="mobile_nav_group">
                    <div class="navigation_item">
                        <div class="notification_baloon" v-if="store.getters.notificacionesPendientes > 0">
                            {{store.getters.notificacionesPendientes}}
                        </div>
                        <router-link v-if="isloged()" @click="ocmenu()" to="/perfil">Mi cuenta</router-link>
                    </div>
                    <div class="navigation_item small_navigation_item">
                        <router-link v-if="isloged()" @click="ocmenu()" to="/perfil?tab=perfil" :class="(route.name == 'miPerfil' && (!route.query.tab || route.query.tab == 'perfil')) ? 'active_item' : '' ">Configuración</router-link>
                    </div>
                    <div class="navigation_item small_navigation_item">
                        <router-link v-if="isloged()" @click="ocmenu()" to="/perfil?tab=muro" :class="(route.name == 'miPerfil' && route.query.tab == 'muro') ? 'active_item' : '' ">Mi muro</router-link>
                    </div>
                    <div class="navigation_item small_navigation_item">
                        <router-link v-if="isloged()" @click="ocmenu()" to="/perfil?tab=canal" :class="(route.name == 'miPerfil' && route.query.tab == 'canal') ? 'active_item' : '' ">Mi canal</router-link>
                    </div>
                    <div class="navigation_item small_navigation_item">
                        <router-link v-if="isloged()" @click="ocmenu()" to="/perfil?tab=notificaciones" :class="(route.name == 'miPerfil' && route.query.tab == 'notificaciones') ? 'active_item' : '' ">Noticaciones</router-link>
                    </div>
                </div>
                <div class="mobile_nav_separator"></div>
                <div class="mobile_nav_group">
                    <div class="navigation_item">
                        <router-link @click="ocmenu()" to="/contacto">Contacto</router-link>
                    </div>
                    <div class="navigation_item weather_item">
                        <button v-if="!isloged()" @click="()=>{ emitter.emit('popUpLogin', 1); }" >login</button>
                    </div>
                    <div class="navigation_item">
                        <button v-if="!isloged()" @click="()=>{emitter.emit('popUpLogin', 2);}">Registro</button>
                    </div>
                    <div class="navigation_item login">
                        <button v-if="isloged()" @click="logout(store)">Logout</button>
                    </div>
                </div>
                <div class="mobile_nav_separator"></div>
                <div class="mobile_nav_group">
                    <div class="social_menu">
                        <div class="social_menu_item">
                            <a href="https://www.instagram.com/nomadastvoficial/" target="_blank">
                                <img src="@/assets/images/instagram_logo.png" alt="Instagram">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </header>
</template>
<script setup>
    import { useStore } from 'vuex';
    import { logout } from '@/composables/user.js';
    /* Search */
    import MySuggester from '@/components/MySuggester.vue';
    import { inject, ref } from "vue";
    import axios from 'axios';
    import { useRouter, useRoute } from "vue-router";
    import { openChatEmit } from "@/composables/varios.js";
    
    const router = useRouter();
    const route = useRoute();
    const searcher = ref(null);
    let timeoutHandle;

    defineProps({
        msg_pet: {
            type: Object
        }
    });

    const emitter = inject("emitter");
    const store = useStore();
    const isloged = () => { return store.state.token ? true : false; }

    const searchItems = ref([]);
    const searchDropDown = ref(false);
    const getSearch = (imp) => {
        if(imp.input.length > 0)
            searchDropDown.value=true;
        else{
            searchDropDown.value=false;
            return;
        }
        clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(()=>{
            axios.get(`${process.env.VUE_APP_URL}search/all/${imp.input}`)
            .then(response => {
                var rs = response.data;
                searchItems.value = [];
                if(rs.data.p.length){
                    let elements = [];
                    rs.data.p.forEach((p) => {
                        elements.push({n: p.hashtag, t: "h", "type": "header"});
                    });
                    searchItems.value.push({type:"header", "n": "Hashtags", elements: elements});
                }
                if(rs.data.u.length){
                    let elements = [];
                    rs.data.u.forEach((u) => {
                        elements.push({n: u.username, img: u.img_perfil, t: "u", "type": "header"});
                    });
                    searchItems.value.push({type:"header", "n": "Usuarios", elements: elements});
                }
                if(rs.data.c.length){
                    let elements = [];
                    rs.data.c.forEach((c) => {
                        elements.push({n: c.nombre, url: c.url, img: c.img_perfil, t: "c", "type": "header"});
                    });
                    searchItems.value.push({type:"header", "n": "Canales", elements: elements});
                }
            });
        }, 500) ;
    }
    const selectItem = (val) => {
        val = val.input;
        if(val.t == "h"){
            router.push(`/tags/${val.n}`);
        }else if(val.t == "u"){
            router.push(`/perfil/${val.n}`);
        }else if(val.t == "c"){
            router.push(`/canales/${val.url}`);
        }
        searchItems.value =false;
        searcher.value.clearSuggester();
    }
    

    /* </Search> */

    const ocmenu = () => {
        if(document.getElementById("navegacion").classList.contains("visible")){
            document.getElementById("navegacion").classList.remove("visible");
            document.getElementById("trigger").classList.remove("close_btn");
        }
        else{
            document.getElementById("navegacion").classList.add("visible");
            document.getElementById("trigger").classList.add("close_btn");
        }
    }   
</script>