import L from "leaflet";

function getLocation(cb){
    let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(cb, cb, options);
    } else {
        cb(false);
    }
}

function randomGeo(latitude, longitude, radius) {
    var y0 = latitude;
    var x0 = longitude;
    var rd = radius / 111300; //about 111300 meters in one degree

    var u = Math.random();
    var v = Math.random();

    var w = rd * Math.sqrt(u);
    var t = 2 * Math.PI * v;
    var x = w * Math.cos(t);
    var y = w * Math.sin(t);

    //Adjust the x-coordinate for the shrinking of the east-west distances
    var xp = x / Math.cos(y0);

    var newlat = y + y0;
    var newlon = x + x0;
    var newlon2 = xp + x0;

    return {
        'latitude': newlat.toFixed(5),
        'longitude': newlon.toFixed(5),
        'longitude2': newlon2.toFixed(5),
        'distance': distancePoints(latitude, longitude, newlat, newlon).toFixed(2),
        'distance2': distancePoints(latitude, longitude, newlat, newlon2).toFixed(2),
    };
}


function distancePoints(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2-lat1);
    var dLon = toRad(lon2-lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
}

function toRad(Value) {
    return Value * Math.PI / 180;
}

const normalSize = {
    iconSize: [42, 42],
    iconAnchor: [21, 42],
    popupAnchor: [0, -42]
}

const MapsIcons = {
    // iconcar:  L.icon({iconUrl: require("@/assets/images/gas.svg"), iconSize: [54, 54], iconAnchor: [27, 54], popupAnchor: [0, -54]}),
    // iconfarm: L.icon({iconUrl: require("@/assets/images/cruz.svg"), iconSize: [54, 54], iconAnchor: [27, 54], popupAnchor: [0, -54]}),
    // iconareas: L.icon({iconUrl: require("@/assets/images/area.svg"), iconSize: [54, 54], iconAnchor: [27, 54], popupAnchor: [0, -54]}),
    iconglp: L.icon({iconUrl: require("@/assets/images/glp.svg"), iconSize: normalSize.iconSize, iconAnchor: normalSize.iconAnchor, popupAnchor: normalSize.popupAnchor}),
    // iconlavan: L.icon({iconUrl: require("@/assets/images/lavan.svg"), iconSize: [54, 54], iconAnchor: [27, 54], popupAnchor: [0, -54]}),
    // iconlocal: L.icon({iconUrl: require("@/assets/images/p_local.svg"), iconSize: [54, 54], iconAnchor: [27, 54], popupAnchor: [0, -54]}),
    iconubic: L.icon({iconUrl: require("@/assets/images/me.svg"), iconSize: [54, 54], iconAnchor: [27, 54], popupAnchor: [0, -54]}),
    iconiniruta: L.icon({iconUrl: require("@/assets/images/inicio.svg"), iconSize: [54, 54], iconAnchor: [27, 54], popupAnchor: [0, -54]}),
    iconfinruta: L.icon({iconUrl: require("@/assets/images/destino.svg"), iconSize: [54, 54], iconAnchor: [27, 54], popupAnchor: [0, -54]}),
    default: L.icon({iconUrl: require("@/assets/images/default.svg"), iconSize: normalSize.iconSize, iconAnchor: normalSize.iconAnchor, popupAnchor: normalSize.popupAnchor}),

};

function correctLngLat(lng, lat){
    if(!isNaN(lat) && !isNaN(lng)){
        if(
            (-180 < lng && lng < 180) &&
            (-90 < lat && lat < 90)
        ){
            return true;
        }
    }
    return false;
}


export {distancePoints, randomGeo,  getLocation, correctLngLat, MapsIcons, normalSize};