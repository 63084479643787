<template>
    <div class="chat_wrapper">
        <div id="chat_trigger" class="chat_trigger visible" @click="openChat">
            <div v-if="numChatsNoVistos > 0" class="chats_baloon">{{numChatsNoVistos}}</div>
            <div class="chat_trigger_btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 9.352c0-4.852-4.75-8.352-10-8.352-5.281 0-10 3.527-10 8.352 0 1.71.615 3.39 1.705 4.695.047 1.527-.85 3.719-1.66 5.312 2.168-.391 5.252-1.258 6.648-2.115 7.698 1.877 13.307-2.842 13.307-7.892zm-14.5 1.381c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm7.036 1.441c-.161.488-.361.961-.601 1.416 1.677 1.262 2.257 3.226.464 5.365-.021.745-.049 1.049.138 1.865-.892-.307-.979-.392-1.665-.813-2.127.519-4.265.696-6.089-.855-.562.159-1.145.278-1.74.364 1.513 1.877 4.298 2.897 7.577 2.1.914.561 2.933 1.127 4.352 1.385-.53-1.045-1.117-2.479-1.088-3.479 1.755-2.098 1.543-5.436-1.348-7.348z"></path></svg>
                <span>Chatea con<br/>otros nomadas</span>
            </div>
        </div>
        <div id="chatdiv" class="chat content_block boxed dynamic__container container__right">
            <div class="chat_header bordered">
                <h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 9.352c0-4.852-4.75-8.352-10-8.352-5.281 0-10 3.527-10 8.352 0 1.71.615 3.39 1.705 4.695.047 1.527-.85 3.719-1.66 5.312 2.168-.391 5.252-1.258 6.648-2.115 7.698 1.877 13.307-2.842 13.307-7.892zm-14.5 1.381c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm7.036 1.441c-.161.488-.361.961-.601 1.416 1.677 1.262 2.257 3.226.464 5.365-.021.745-.049 1.049.138 1.865-.892-.307-.979-.392-1.665-.813-2.127.519-4.265.696-6.089-.855-.562.159-1.145.278-1.74.364 1.513 1.877 4.298 2.897 7.577 2.1.914.561 2.933 1.127 4.352 1.385-.53-1.045-1.117-2.479-1.088-3.479 1.755-2.098 1.543-5.436-1.348-7.348z"/></svg>Chat
                </h3>
                <div @click="closeChat" class="block_header_menu_trigger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.024 4h6.015l7.961 8-7.961 8h-6.015l7.961-8-7.961-8zm-10.024 16h6.015l7.961-8-7.961-8h-6.015l7.961 8-7.961 8z"/></svg>
                </div>
            </div>
            <div class="chat_container">
                <div class="chat_tabs">
                    <div id="tabchat" class="chat_tab tab_active" @click="changeChatTab">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 3.002c4.411 0 8 2.849 8 6.35 0 3.035-3.029 6.311-7.925 6.311-1.58 0-2.718-.317-3.718-.561-.966.593-1.256.813-3.006 1.373.415-1.518.362-2.182.331-3.184-.837-1.001-1.682-2.069-1.682-3.939 0-3.501 3.589-6.35 8-6.35zm0-2.002c-5.281 0-10 3.526-10 8.352 0 1.711.615 3.391 1.705 4.695.047 1.527-.851 3.718-1.661 5.312 2.168-.391 5.252-1.258 6.649-2.115 1.181.289 2.312.421 3.382.421 5.903 0 9.925-4.038 9.925-8.313 0-4.852-4.751-8.352-10-8.352zm11.535 11.174c-.161.488-.361.961-.601 1.416 1.677 1.262 2.257 3.226.464 5.365-.021.745-.049 1.049.138 1.865-.892-.307-.979-.392-1.665-.813-2.127.519-4.265.696-6.089-.855-.562.159-1.145.278-1.74.364 1.513 1.877 4.298 2.897 7.577 2.1.914.561 2.933 1.127 4.352 1.385-.53-1.045-1.117-2.479-1.088-3.479 1.755-2.098 1.543-5.436-1.348-7.348zm-15.035-3.763c-.591 0-1.071.479-1.071 1.071s.48 1.071 1.071 1.071 1.071-.479 1.071-1.071-.48-1.071-1.071-1.071zm3.5 0c-.591 0-1.071.479-1.071 1.071s.48 1.071 1.071 1.071 1.071-.479 1.071-1.071-.48-1.071-1.071-1.071zm3.5 0c-.591 0-1.071.479-1.071 1.071s.48 1.071 1.071 1.071 1.071-.479 1.071-1.071-.48-1.071-1.071-1.071z"/></svg>Chats
                    </div>
                    <div id="tabfriends" class="chat_tab" @click="changeChatTab">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"/></svg>Amigos
                    </div>
                </div>
                <div class="chat_window">
                    <div id="chatswap" class="chats_wrapper tab_01_selected">
                        <div class="chats_list chats_history">
                            <div class="chats_list_item" :class="usersBlocked.includes(username) ? 'user_blocked' : ''"   v-for="data,username in userChats" :key="username" @click="openUserChat(username)">
                                <div class="chat_user_photo" :title="username">
                                    <img :src="data.img" :alt="username" width="34" height="34">
                                </div>
                                <div class="chat_user_data">
                                    <div class="chat_user_last_date">{{  data.chats[0].time  }}</div>
                                    <div class="chat_user_name">{{username}}</div>
                                    <div class="chat_user_last_chat">{{ data.chats[0].msg }}</div>
                                    <span v-if="usersBlocked.includes(username)"  class="user_status_message">Usuario bloqueado</span>
                                    <div v-if="data.novisto > 0" class="chats_baloon">{{ data.novisto }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="chats_list chats_friends">
                            <div class="chats_list_item" :class="usersBlocked.includes(user.username) ? 'user_blocked' : ''" v-for="user,index in store.getters.getFriends" :key="index" @click="openUserChat(user.username, user.img)">
                                <div class="chat_user_photo" :title="user.username">
                                    <img :src="user.img" :alt="user.username" width="34" height="34">
                                </div>
                                <div class="chat_user_data">
                                    <div class="chat_user_name">{{ user.username }}</div>
                                </div>
                                <span v-if="usersBlocked.includes(user.username)" class="user_status_message">Usuario bloqueado</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="actual_chat" class="actual_chat">
                    <template v-if="actualChat">
                        <div class="chat_user" :class="usersBlocked.includes(actualChat.name) ? 'user_blocked' : ''">
                            <div @click="closeUserChat" class="back_button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                            <div class="chat_user_photo" :title="actualChat.name">
                                <img :src="actualChat.img" :alt="actualChat.name" width="34" height="34">
                            </div>
                            <div class="chat_user_name">{{ actualChat.name }}</div>
                            <div @click="blockUser(actualChat.name)" class="block_user_btn">
                                <span>Bloquear usuario</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm9 12c0 1.94-.624 3.735-1.672 5.207l-12.535-12.535c1.472-1.048 3.267-1.672 5.207-1.672 4.962 0 9 4.038 9 9zm-18 0c0-1.94.624-3.735 1.672-5.207l12.534 12.534c-1.471 1.049-3.266 1.673-5.206 1.673-4.962 0-9-4.038-9-9z"/></svg>
                            </div>
                            <span v-if="usersBlocked.includes(actualChat.name)" class="user_status_message">Usuario bloqueado</span>
                        </div>
                        <div class="conversation">
                            <template v-for="data,index in actualChat.chats"  :key="index">
                                <span :class="data.robot ? 'robot_balloon' : (data.send ? 'sender_balloon' : 'receiver_balloon')" ><b v-if="data.robot" class="official_chat">Mensaje oficial de Nomadas.TV</b>{{data.msg}}<span class="message_date">{{ dateToChatDateTimeStr(data.date) }}</span></span>
                                <div v-if="index == actualChat.chats.length -1 || ( dateToChatDateTimeStr(actualChat.chats[index + 1].date, 2) !== dateToChatDateTimeStr(data.date, 2))" class="chat_separator_info">
                                    <span>{{ dateToChatDateTimeStr(data.date, 2) }} </span>
                                </div>
                            </template>
                        </div>
                        <div class="conversation_text_enter">
                            <EmojiSelector @emojiSelect="(e)=>{ conversation_text.value += e }"/>
                            <textarea class="conversation_text" ref="conversation_text" placeholder="Mensaje" v-on:keyup.enter="sendChat"></textarea>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, inject } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex';
    import { dateToChatDateTimeStr, seeRobotsChats } from '@/composables/chat.js';
    import EmojiSelector from '@/components/EmojiSelector.vue';

    
    axios.defaults.timeout = 20000;
    const emitter = inject('emitter');
    const userChats = ref({});
    const configAxios =  {headers: {}}
    const store = useStore();
    const actualChat = ref(false);
    const numChatsNoVistos = ref(0);
    const usersBlocked = ref([]);
    const audioNotif = new Audio(require("/src/assets/notif.mp3"));
    const conversation_text = ref("");

    emitter.on("chatmsg", (m) => {
        let date = new Date();
        if(m.ta == "new"){
            // let chat =  {"send":false, "msg":  m.msg, "time": date.getHours() + ":" + date.getMinutes(), "date": date.getDate() + " de "+ monthNames[date.getMonth()]};
            let chat =  {"send":false, "msg":  m.msg, "date": date};
            if(userChats.value[m.from]){
                userChats.value[m.from].chats.unshift(chat);
                if(!actualChat.value || actualChat.value.name != m.from){
                    userChats.value[m.from].novisto++;
                    soundNotification();
                    chatsNoVistos();
                }else{
                    setVisto(m.from);
                }
            }
            else{
                // perfilData(m.from, (img)=>{
                    let ch =  {"send":false, "msg":  m.msg, "date": date};
                    addUserToChats(m.from, m.img, ch);
                    userChats.value[m.from].novisto++;
                    chatsNoVistos();
                    soundNotification();
                // });
            }
        }else if( m.ta == "gblock"){
            usersBlocked.value = m.users;
            m.users.forEach((u)=>{
                if(userChats.value[u])
                    userChats.value[m.from].block = true;
            });
            
        }
    });

    emitter.on("openchat", (params) => {
        openChat();
        if(params && params.username && params.img)
            openUserChat(params.username, params.img);
    });

    onMounted(() => {
        if(store.state.token){
            configAxios.headers = {"API-TOKEN": store.state.token};
            getChats();
        }
        
    })

    const soundNotification = () => {
        if(audioNotif.readyState >= 2){
            audioNotif.pause();
            audioNotif.currentTime = 0;
            audioNotif.play();
        }
    }
    const blockUser = (username) => {
        if(usersBlocked.value.includes(username)){
            let index = usersBlocked.value.indexOf(username);
            if (index > -1) {
                usersBlocked.value.splice(index, 1);
            }
        }else{
            usersBlocked.value.push(username);
        }
        emitter.emit("sendmsg", JSON.stringify({"t":"c", "ta": "block", "to": username }));
    }

    const chatsNoVistos = () => {
        let val = 0;
        Object.keys(userChats.value).forEach(key => {
            val += userChats.value[key].novisto;
        });
        numChatsNoVistos.value = val;
    }

    const getChats = () => {
        axios
            .get(process.env.VUE_APP_URL+'chats', configAxios)
            .then(response => {
                var rs = response.data;
                if(rs.state == 'OK'){
                    userChats.value = rs.data;
                    chatsNoVistos();
                }
            });
    }


    const openChat = () => {
        document.getElementById("chat_trigger").classList.remove("visible");
        document.getElementById("chatdiv").classList.add("visible");
    }

    const closeChat = () => {
        document.getElementById("chat_trigger").classList.add("visible");
        document.getElementById("chatdiv").classList.remove("visible");
        closeUserChat();
    }

    const openUserChat = (username, img) => {
        if(!userChats.value[username]){
            if(!img){
                img = null;
            }else{
                actualChat.value = {name: username, chats: [], img: img } ;
            }
        }else
            actualChat.value = { name: username, chats: userChats.value[username].chats, img: userChats.value[username].img, block: userChats.value[username].block  } ;
        document.getElementById("actual_chat").classList.add("visible");
        setVisto(username);
    }

    const setVisto = (chatUsername) => {
        if(userChats.value[chatUsername]){
            emitter.emit("sendmsg", JSON.stringify({"t":"c", "ta": "see", "to": chatUsername }));
            userChats.value[chatUsername].novisto = 0;
            chatsNoVistos();
        }
        if(chatUsername == "nomadastv"){
            seeRobotsChats();
        }
    }

    const closeUserChat = () => {
        actualChat.value = false;
        document.getElementById("actual_chat").classList.remove("visible");
    }

    const sendChat = (e) => {
        if (/\S/.test(e.target.value)){
            emitter.emit("sendmsg", JSON.stringify({"t":"c", "ta": "send", "txt": e.target.value, "to": actualChat.value.name }));
            let date = new Date();
            let chat = {"send":true, "msg":  e.target.value, "date": date};
            if(!userChats.value[actualChat.value.name]){
                let username =actualChat.value.name;
                let img = actualChat.value.img;
                addUserToChats(username, img, chat);
                actualChat.value.chats = userChats.value[username].chats;
            }else
                userChats.value[actualChat.value.name].chats.unshift(chat);
            e.target.value = "";
        }
    }

    const addUserToChats = (username, img, chat) => {
        if(Object.keys(userChats.value).length == 0){
            let obj = {}
            obj[username] = {chats:[chat], "img": img, novisto:0};
            userChats.value = obj;
        }else{
            if(!userChats.value[username])
                userChats.value[username] = {chats:[chat], "img": img, novisto:0};
            else
                userChats.value[username].chats.push(chat);
        }
    }

    const changeChatTab = (e) => {
        if(!e.target.classList.contains("tab_active")){
            if(e.target.id == "tabchat"){
                document.getElementById("tabfriends").classList.remove("tab_active");
                document.getElementById("tabchat").classList.add("tab_active");
                document.getElementById("chatswap").classList.remove("tab_02_selected");
                document.getElementById("chatswap").classList.add("tab_01_selected");
                
            }else{
                document.getElementById("tabchat").classList.remove("tab_active");
                document.getElementById("tabfriends").classList.add("tab_active");
                document.getElementById("chatswap").classList.remove("tab_01_selected");
                document.getElementById("chatswap").classList.add("tab_02_selected");
            }
        }
    }

</script>

<style scoped>
/*----------------------------------------------------------------------------------- 
CHAT
-----------------------------------------------------------------------------------*/
.chat {background-color: #585858;font-family: 'Open sans', sans-serif;position: fixed;top: 70px;right: 0;bottom: 20px;z-index: 99;width: 100%;overflow: hidden;z-index: 9999;
    box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 30%);
}
.chat .chat_header {background-color: #f9b238;border-bottom: solid 1px #5d5d5d;
	display: flex;
	justify-content: space-between;
}
.chat .chat_header h3 {color: #000;font-size: 16px;line-height: 16px;padding: 10px 12px;}
.chat .chat_header h3 svg {fill: #000;width: 16px;height: 16px;margin-right: 7px;vertical-align: top;}
.chat .block_header_menu_trigger svg {fill: #000;}
.chat .block_header_menu_trigger:hover {background-color: #ffcb6d;}
.chat_window {position: relative;height: calc(100vh - 197px);overflow-y: auto;overflow-x: hidden;}
.chat_container {height: calc(100% - 37px);position: relative;}
.chat_container .chat_tabs {border-bottom: solid 1px #424242;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}
.chat_container .chat_tabs .chat_tab {width: 100%;padding: 10px 10px 6px 10px;
    display: flex;
    justify-content: center;
}
.chat_container .chat_tabs .chat_tab {border-bottom: solid 4px #585858;background-color: #585858;cursor: pointer;color: #b9b9b9;font-size: 14px;position: relative;}
.chat_container .chat_tabs .chat_tab:hover, .chat_container .chat_tabs .chat_tab.tab_active {background-color: #6e6e6e;}
.chat_container .chat_tabs .chat_tab.tab_active {border-color: #03d117;}
.chat_container .chat_tabs .chat_tab svg {fill: #b9b9b9;margin-right: 10px;width: 18px;height: 18px;}
.chat_container .chat_tabs .chat_tab:hover {border-color: #fff;}
.chat_container .chat_tabs .chat_tab:hover, .chat_container .chat_tabs .chat_tab.tab_active {color: #fff;}
.chat_container .chat_tabs .chat_tab:hover svg, .chat_container .chat_tabs .chat_tab.tab_active svg {fill: #fff;}
.chat_container .chat_tabs .chat_tab.tab_active:hover {border-color: #03d117;}
.chats_wrapper {position: relative;width: 100%;height: 100%;}
.chat_window .chats_list {width: 100%;height: 100%;position: absolute;top: 0;overflow-y: auto;
    -webkit-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -moz-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -o-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -ms-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
}
/* .chat_window .chats_list.chats_history {background-color: #f60;}*/
/* .chat_window .chats_list.chats_friends {background-color: #fF0;}*/
.chats_wrapper.tab_01_selected .chats_list.chats_history {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.chats_wrapper.tab_01_selected .chats_list.chats_friends {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}
.chats_wrapper.tab_02_selected .chats_list.chats_history {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}
.chats_wrapper.tab_02_selected .chats_list.chats_friends {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.chat_window .chats_list .chats_list_item {border-bottom: solid 1px #707070;cursor: pointer;display: flex;padding: 15px 10px;position: relative;}
.chat_window .chats_list .chats_list_item:hover {background-color: #676767;}
.chat_window .chats_list .chats_list_item.user_blocked, .chat_window .chats_list .chats_list_item.user_blocked:hover {background-color: #ba4f4f;}
.chat_window .chats_list .chats_list_item .chat_user_photo {overflow: hidden;margin-right: 10px;width: 40px;border-radius: 40px;}
.chat_window .chats_list .chats_list_item .chat_user_last_date {color: #fff;font-size: 12px;position: absolute;right: 10px;}
.chat_window .chats_list .chats_list_item .chat_user_name {color: #fff;font-size: 14px;margin-bottom: 3px;font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.chat_window .chats_list .chats_list_item .chats_baloon {background-color: #22a535;color: #fff;font-size: 13px;font-weight: 500;position: absolute;right: 10px;bottom: 15px;width: 20px;height: 20px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat_window .chats_list .chats_list_item .chat_user_last_chat {color: #d1d1d1;font-size: 12px;font-weight: 400;max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.chat_container .actual_chat {background-color: #585858;position: absolute;width: 100%;height: 100%;z-index: 10;left: 0;top: 0;
    display: flex;
    flex-direction: column;
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    -webkit-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -moz-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -o-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -ms-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
}
.chat_container .actual_chat.visible {
    -webkit-transform: none;
    transform: none;
}
.chat_container .actual_chat .chat_user {overflow: hidden;padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.chat_container .actual_chat .chat_user.user_blocked {background-color: #ba4f4f;}
.chat_container .actual_chat .chat_user .back_button {cursor: pointer;margin-right: 10px;width: 18px;height: 18px;}
.chat_container .actual_chat .chat_user .back_button svg {fill: #fff;width: 18px;height: 18px;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.chat_container .actual_chat .chat_user .chat_user_photo {overflow: hidden;margin-right: 10px;width: 35px;border-radius: 35px;}
.chat_container .actual_chat .chat_user .chat_user_last_date {color: #fff;font-size: 12px;position: absolute;right: 10px;}
.chat_container .actual_chat .chat_user .chat_user_name {color: #fff;font-size: 14px;margin-bottom: 3px;font-weight: 700;}
.chat_container .actual_chat .chat_user .block_user_btn {cursor: pointer;margin-left: auto;position: relative;z-index: 2;}
.chat_container .actual_chat .chat_user .block_user_btn span {background-color: #f00;color: #fff;font-size: 12px;font-weight: 700;padding: 3px 4px;position: absolute;left: -129px;top: -2px;
    display: none;
    border-radius: 3px;
    }
.chat_container .actual_chat .chat_user .block_user_btn:hover span {display: block;}
.chat_container .actual_chat .chat_user .block_user_btn svg {fill: #a8a8a8;width: 18px;height: 18px;}
.chat_container .actual_chat .chat_user .block_user_btn:hover svg, .chat_container .actual_chat .chat_user .block_user_btn.active svg {fill: #f00;}
.chat_container .actual_chat .chat_user .block_user_btn.active span {background-color: #089db1;}
.chat_container .actual_chat .conversation {background-color: #4a4a4a;padding: 10px;overflow-y: scroll;height: calc(100vh - 254px);
    display: flex;
    flex-direction: column-reverse;
    background-color: #1f1f1f;
    opacity: 1;
    background-image:  linear-gradient(30deg, #272727 12%, transparent 12.5%, transparent 87%, #272727 87.5%, #272727), linear-gradient(150deg, #272727 12%, transparent 12.5%, transparent 87%, #272727 87.5%, #272727), linear-gradient(30deg, #272727 12%, transparent 12.5%, transparent 87%, #272727 87.5%, #272727), linear-gradient(150deg, #272727 12%, transparent 12.5%, transparent 87%, #272727 87.5%, #272727), linear-gradient(60deg, #27272777 25%, transparent 25.5%, transparent 75%, #27272777 75%, #27272777), linear-gradient(60deg, #27272777 25%, transparent 25.5%, transparent 75%, #27272777 75%, #27272777);
    background-size: 18px 32px;
    background-position: 0 0, 0 0, 9px 16px, 9px 16px, 0 0, 9px 16px;
}
.chat_container .actual_chat .conversation .sender_balloon, .chat_container .actual_chat .conversation .receiver_balloon, .chat_container .actual_chat .conversation .robot_balloon {color: #fff;font-size: 14px;max-width: 90%;position: relative;
    -webkit-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
}
.chat_container .actual_chat .conversation .receiver_balloon {background-color: #373737;margin: 3px auto 3px 10px;padding: 5px 35px 5px 10px;
    -webkit-border-radius: 10px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: 10px;
    -moz-border-radius-topleft: 0;
    border-radius: 10px;
    border-top-left-radius: 0;
}
.chat_container .actual_chat .conversation .receiver_balloon::before {content: '';position: absolute;left: -8px;top: 0;
    width: 0;
    height: 0;
    border-right: 4px solid #373737;
    border-top: 4px solid #373737;
    border-left: 4px solid transparent;
    border-bottom: 4px solid transparent;
}
.chat_container .actual_chat .conversation .official_chat {background-color: #95ffc3;display: block;text-align: center;border-radius: 7px;padding: 5px 10px;margin-bottom: 10px;}
.chat_container .actual_chat .conversation .sender_balloon {background-color: #1e7203;margin: 3px 10px 3px auto;padding: 5px 35px 5px 10px;
    -webkit-border-radius: 10px;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius: 10px;
    -moz-border-radius-topright: 0;
    border-radius: 10px;
    border-top-right-radius: 0;
}
.chat_container .actual_chat .conversation .sender_balloon::before {content: '';position: absolute;right: -8px;top: 0;
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-top: 4px solid #1e7203;
    border-left: 4px solid #1e7203;
    border-bottom: 4px solid transparent;
}
.chat_container .actual_chat .conversation .robot_balloon {background-color: #eca935;color: #000;font-weight: bold;margin: 3px auto 3px 10px;padding: 5px 35px 5px 10px;
    -webkit-border-radius: 10px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: 10px;
    -moz-border-radius-topleft: 0;
    border-radius: 10px;
    border-top-left-radius: 0;
}
.chat_container .actual_chat .conversation .robot_balloon::before {content: '';position: absolute;left: -8px;top: 0;
    width: 0;
    height: 0;
    border-right: 4px solid #eca935;
    border-top: 4px solid #eca935;
    border-left: 4px solid transparent;
    border-bottom: 4px solid transparent;
}
.chat_container .actual_chat .conversation .message_date {font-size: 8px;display: inline-block;position: absolute;bottom: 7px;right: 7px;}
.chat_container .actual_chat .conversation .chat_separator_info {margin: 15px 0;text-align: center;}
.chat_container .actual_chat .conversation .chat_separator_info span {background-color: #373737;color: #959595;font-size: 11px;font-weight: 700;padding: 5px 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #646464 #333;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #333;
}
*::-webkit-scrollbar-thumb {
  background-color: #646464;
  border-radius: 20px;
  border: 3px solid #333;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #8b8b8b;
  border: 3px solid #646464;
}

.conversation_text_enter {
    display: flex;
}
.conversation_text_enter .emoji_btn {cursor: pointer;width: 50px;height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.conversation_text_enter .emoji_btn svg {fill: #a0a0a0;width: 19px;height: 19px;}
.conversation_text_enter .emoji_btn:hover {background-color: #717171;}
.conversation_text_enter .emoji_btn:hover svg {fill: #fff;}
.conversation_text_enter .conversation_text {background: none;color: #fff;border: none;font-size: 14px;font-family: 'Roboto', sans-serif;line-height: inherit;max-height: 450px;min-height: 30px;margin: 10px;
    resize: none; padding: 0;
}
.conversation_text_enter .conversation_text::placeholder {line-height: 32px;}
.chat_trigger {background-color: #f9b238;top: 50%;right: 10px;margin-top: -24px;z-index: 99;position: fixed;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 35%);
    -moz-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 35%);
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 35%);
    -webkit-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -moz-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -o-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -ms-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
	-webkit-transform: translate3d(200%,0,0);
    transform: translate3d(200%,0,0);
}
.chat_trigger.visible {
	-webkit-transform: none;
    transform: none;
}
.chat_trigger .chats_baloon {background-color: #f03030;color: #fff;font-size: 1.125rem;font-weight: 700;position: absolute;right: -10px;top: -10px;width: 25px;height: 25px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
}
.chat_trigger_btn {cursor: pointer;color: #000;font-size: 1.5em;font-family: 'Barlow condensed', sans-serif;font-weight: 700;padding: 8px 10px;
    border-radius: 5px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.chat_trigger_btn span {display: none;text-align: center;}
.chat_trigger_btn:hover {background-color: #06c18a;}
.chat_trigger_btn svg {fill: #000;width: 28px;height: 28px}
.user_status_message {background-color: #ba0707;color: #fff;font-size: 12px;margin-left: 10px;padding: 3px 7px;border-radius: 3px;}
.chats_list_item .user_status_message {position: absolute;top: 12px;right: 50px;}
.chat_user .user_status_message {position: absolute;top: 13px;right: 45px;z-index: 1;}

@media (min-width: 360px) {
    .chat {width: 350px;}
}

@media (min-width: 765px) {
    .chat {position: fixed;right: 20px;width: 350px; bottom: 30px;height: auto;}
    .chat_trigger_btn {padding: 12px 15px;}
    .chat_trigger_btn svg {width: 32px;height: 32px}
}

@media (min-width: 1240px) {
    .chat_trigger {margin-top: -55px;}
	.chat_trigger_btn span {display: inline-block;}
}

@media (min-width: 1580px) {
}


</style>