<template>
    <div class="popup_wrapper" v-if="openPopUp && props.msg_popup.msg">
        <div class="popup_window">
            <div class="popup_content">
                <div class="close_btn" @click="() => {openPopUp = false}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"/></svg></div>
                <div class="message message_big info">
                    <div>
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 8c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" fill-rule="nonzero"/></svg>
                    </div>
                    <div>
                        <p>{{ props.msg_popup.msg }}</p>
                    </div>
                </div>
                <div class="popup_nav" v-if="props.msg_popup.botones">
                    <div @click="emitter.emit('popUpLogin', 1)" class="btn med grey graphic_right">Ir a Login<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                    <div @click="emitter.emit('popUpLogin', 2)" class="btn med grey graphic_right">Ir al registro<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { watch, ref, inject} from 'vue';

    const emitter = inject("emitter");
    const props = defineProps({
        msg_popup: {
            type: Object
        }
    });
    const openPopUp = ref(false);
    watch(() => props.msg_popup, (n) => {
        if(n){
            openPopUp.value = true;
        }
    });

</script>

<style scoped>

</style>
