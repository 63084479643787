import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import mitt from 'mitt';  
// import '@/composables/clickOutside.js';
import "@/assets/style.css";
const emitter = mitt();

const app = createApp({extends: App, beforeCreate() { this.$store.commit('initialStore', emitter, router); }});
app.use(store).use(router).provide('emitter', emitter).mount('#app');
app.directive('click-outside', {
    mounted(el, binding) {
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});


axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if(!error || !error.response || ! error.response.status)
            return
        if (error.response.status == 404) {
            // router.push("/404");
            return false;
        }else if (error.response.status == 401) {
            store.commit('logout');
        }else{
            // var msg_pet = {
            //     clase: 'ko',
            //     msg: "Hubo un error, por favor intentalo mas tarde"
            // };
            // emitter.emit("showmsg", msg_pet);
            // router.push('/');
        }
        return false;
    }
);
