<template>
<div class="emoji_selector" :class="props.classTheme"  v-click-outside="()=>{show = false}">
    <div class="emoji_trigger" @click="()=>{show = !show}">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.507 13.941c-1.512 1.195-3.174 1.931-5.506 1.931-2.334 0-3.996-.736-5.508-1.931l-.493.493c1.127 1.72 3.2 3.566 6.001 3.566 2.8 0 4.872-1.846 5.999-3.566l-.493-.493zm-9.007-5.941c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5z"/></svg>
    </div>
    <div class="emoji_list_wrapper" v-if="show">
        <div class="emoji_list">
            <div class="emoji_list_menu tab_menu">
                <span :class="tabSelected == 'people' ? 'active' : ''" class="tab" @click="()=>{tabSelected = 'people'}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.644 17.08c2.866-.662 4.539-1.241 3.246-3.682-3.932-7.427-1.042-11.398 3.111-11.398 4.235 0 7.054 4.124 3.11 11.398-1.332 2.455.437 3.034 3.242 3.682 2.483.574 2.647 1.787 2.647 3.889v1.031h-18c0-2.745-.22-4.258 2.644-4.92zm-12.644 4.92h7.809c-.035-8.177 3.436-5.313 3.436-11.127 0-2.511-1.639-3.873-3.748-3.873-3.115 0-5.282 2.979-2.333 8.549.969 1.83-1.031 2.265-3.181 2.761-1.862.43-1.983 1.34-1.983 2.917v.773z"/></svg></span>
                <span :class="tabSelected == 'nature' ? 'active' : ''" class="tab" @click="()=>{tabSelected = 'nature'}"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M1 15c4.075-1.121 9.51.505 11 6 1.985-5.939 7.953-7.051 11-6-2.467 1.524-3.497 9-11 9s-8.487-7.471-11-9zm8.203-12.081c.008-1.612 1.319-2.919 2.933-2.919 1.615 0 2.926 1.307 2.934 2.919 1.4-.799 3.187-.317 3.995 1.081.807 1.398.331 3.187-1.062 4 1.393.813 1.869 2.602 1.062 4-.808 1.398-2.595 1.88-3.995 1.081-.008 1.612-1.319 2.919-2.934 2.919-1.614 0-2.925-1.307-2.933-2.919-1.4.799-3.188.317-3.995-1.081-.807-1.398-.331-3.187 1.062-4-1.393-.813-1.869-2.602-1.062-4 .807-1.398 2.595-1.88 3.995-1.081zm2.797 2.581c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z"/></svg></span>
                <span :class="tabSelected == 'foods' ? 'active' : ''" class="tab" @click="()=>{tabSelected = 'foods'}"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.415 12.393l1.868-2.289c.425-.544.224-.988-.055-2.165-.205-.871-.044-1.854.572-2.5 1.761-1.844 5.343-5.439 5.343-5.439l.472.37-3.693 4.728.79.617 3.87-4.59.479.373-3.558 4.835.79.618 3.885-4.58.443.347-3.538 4.85.791.617 3.693-4.728.433.338s-2.55 4.36-3.898 6.535c-.479.771-1.425 1.161-2.334 1.167-1.211.007-1.685-.089-2.117.464l-2.281 2.795c2.445 2.962 4.559 5.531 5.573 6.829.771.987.065 2.421-1.198 2.421-.42 0-.853-.171-1.167-.573l-8.36-10.072s-.926.719-1.944 1.518c-3.172-5.184-6.267-11.661-6.267-13.955 0-.128-.034-.924.732-.924.245 0 .493.116.674.344.509.642 5.415 6.513 10.002 12.049m-2.952 3.617l1.953 2.365-4.115 5.055c-.295.378-.736.576-1.182.576-1.198 0-1.991-1.402-1.189-2.428l4.533-5.568z"/></svg></span>
                <span :class="tabSelected == 'activity' ? 'active' : ''" class="tab" @click="()=>{tabSelected = 'activity'}"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M17.422 20.394c-.177-.415-.219-.698-.289-1.118.625-.697 1.189-1.432 1.692-2.204.58-.196 1.271-.438 2.281-.956-.795 1.756-2.08 3.239-3.684 4.278m-8.181 1.212c1.039-.558 1.89-1.193 2.831-1.899 1.012.253 2.079.395 3.194.443l.001.007c.083.435.205.803.362 1.153-1.987.777-4.182.93-6.388.296m-7.24-9.619l1.273 1.217c-.194 1.076-.248 2.069-.234 3.214-.659-1.334-1.04-2.83-1.04-4.418l.001-.013m11.371-9.882l-.758 1.737c-2.139.56-3.384 1.125-5.214 2.107l-2.863-.586c2.128-2.389 5.337-3.74 8.835-3.258m-1.804 11.769c-1.083-.726-1.941-1.464-3.466-2.727l.546-3.576c1.446-.848 2.566-1.239 4.477-1.849.999.687 1.984 1.428 2.934 2.216l-.002.023c-.138 1.739-.42 3.066-.845 4.495-1.196.524-2.41.998-3.644 1.418m-4.758 6.661c-.555-.339-1.072-.728-1.549-1.164-.256-1.921-.361-3.89-.003-5.865l.001-.004 1.716-.745c1.211 1.126 2.346 2.004 3.676 2.928l.063 2.525c-1.323 1.046-2.369 1.738-3.904 2.325m15.108-7.311c-1 .722-1.776 1.225-3.025 1.683l-1.734-2.007c.451-1.449.738-3 .866-4.727l2.499-1.381c1.147 1.872 1.681 4.066 1.394 6.432m-9.918-13.224c-6.623 0-12 5.377-12 12s5.377 12 12 12 12-5.377 12-12-5.377-12-12-12"/></svg></span>
                <span :class="tabSelected == 'places' ? 'active' : ''" class="tab" @click="()=>{tabSelected = 'places'}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm4 14.5c0 .828-1.79 1.5-4 1.5s-4-.672-4-1.5 1.79-1.5 4-1.5 4 .672 4 1.5z"/></svg></span>
                <span :class="tabSelected == 'symbols' ? 'active' : ''" class="tab" @click="()=>{tabSelected = 'symbols'}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 23h-2.784l-1.07-3h-4.875l-1.077 3h-2.697l4.941-13h2.604l4.958 13zm-4.573-5.069l-1.705-4.903-1.712 4.903h3.417zm-9.252-10.804c.126-.486.201-.852.271-1.212l-2.199-.428c-.036.185-.102.533-.22 1-.742-.109-1.532-.122-2.332-.041.019-.537.052-1.063.098-1.569h2.456v-2.083h-2.161c.106-.531.198-.849.288-1.149l-2.147-.645c-.158.526-.29 1.042-.422 1.794h-2.451v2.083h2.184c-.058.673-.093 1.371-.103 2.077-2.413.886-3.437 2.575-3.437 4.107 0 1.809 1.427 3.399 3.684 3.194 2.802-.255 4.673-2.371 5.77-4.974 1.134.654 1.608 1.753 1.181 2.771-.396.941-1.561 1.838-3.785 1.792v2.242c2.469.038 4.898-.899 5.85-3.166.93-2.214-.132-4.635-2.525-5.793zm-2.892 1.531c-.349.774-.809 1.543-1.395 2.149-.09-.645-.151-1.352-.184-2.107.533-.07 1.072-.083 1.579-.042zm-3.788.724c.062.947.169 1.818.317 2.596-1.996.365-2.076-1.603-.317-2.596z"/></svg></span>
                <span :class="tabSelected == 'flags' ? 'active' : ''" class="tab" @click="()=>{tabSelected = 'flags'}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4 24h-2v-24h2v24zm18-21.387s-1.621 1.43-3.754 1.43c-3.36 0-3.436-2.895-7.337-2.895-2.108 0-4.075.98-4.909 1.694v12.085c1.184-.819 2.979-1.681 4.923-1.681 3.684 0 4.201 2.754 7.484 2.754 2.122 0 3.593-1.359 3.593-1.359v-12.028z"/></svg></span>
            </div>
            <div class="tab_content">
                <div class="emoji_group" v-for="e,index in emojis" :key="index">
                    <div v-if="tabSelected == index">
                        <span v-for="emoji, index in e" :key="index"  @click="emit('emojiSelect', emoji['emoji'])">{{emoji["emoji"]}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script setup>
    import { ref } from 'vue';
    import emojis from "@/assets/files/emojis.json";
    
    const tabSelected = ref("people");
    const emit = defineEmits(['emojiSelect'])
    const show = ref(false);

    const props = defineProps({
        classTheme: {
            type: String,
            default: "emoji_light"
        }
    });

</script>

<style scoped>
    .emoji_selector {position: relative;}
    .emoji_selector:hover .emoji_list_wrapper {display: block;}
    .emoji_trigger {height: 100%;cursor: pointer;padding: 10px;
        display: flex;
        align-items: center;
    }
    .chat .emoji_trigger svg {fill: #fff;}
    .emoji_trigger:hover svg {fill: #e5be01;}
    .emoji_list_wrapper {background-color: #fff;position: absolute;bottom: 45px;left: 6px;z-index: 2;
        border-radius: 5px;
        box-shadow: 0px 5px 15px -3px rgba(0,0,0,0.4);
    }
    .emoji_list_wrapper::before {content: '';position: absolute;bottom: -8px;
        left: 6px;
        width: 0;
        height: 0;
        border-top: 10px solid #fff;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
    .emoji_list {padding: 10px;width: 310px;height: 300px;}
    .emoji_list .emoji_list_menu {margin-bottom: 10px;
        display: flex;
    }
    .emoji_list .emoji_group span {cursor: pointer;font-size: 28px;}
    .tab_menu {border-bottom: solid 1px #b1b1b1;padding-top: 0;
        display: flex;
        flex-wrap: nowrap;
        }
    .tab_menu .tab {cursor: pointer;text-align: center;padding-bottom: 7px;width: 100%;}
    .tab_menu .tab svg {fill: #4b4b4b;width: 18px;height: 18px;}
    .tab_menu .tab.active {border-bottom: solid 3px #333;padding-bottom: 4px;}
    .tab_content {height: 240px;overflow-y: scroll;}

    .emoji_dark .emoji_list_wrapper {background-color: #313131;}
    .emoji_dark .emoji_list_wrapper::before {border-top: 10px solid #313131;}
    .emoji_dark .tab_menu {
    border-bottom: solid 1px #e4e4e4;
}
    .emoji_dark .tab_menu .tab svg {
        fill: #e4e4e4;
        height: 18px;
    }
    .emoji_dark .tab_menu .tab.active {
        border-bottom: solid 3px #fff;
    }

    /* Works on Firefox */
    .emoji_dark .emoji_list {width: 380px;}
    .emoji_dark .tab_content {
    scrollbar-width: thin;
    scrollbar-color: #646464 #333;
    }
    /* Works on Chrome, Edge, and Safari */
    .emoji_dark .tab_content::-webkit-scrollbar {
    width: 12px;
    }
    .emoji_dark .tab_content::-webkit-scrollbar-track {
    background: #333;
    }
    .emoji_dark .tab_content::-webkit-scrollbar-thumb {
    background-color: #646464;
    border-radius: 20px;
    border: 3px solid #333;
    }
    .emoji_dark .tab_content::-webkit-scrollbar-thumb:hover {
    background-color: #8b8b8b;
    border: 3px solid #646464;
    }
    .emoji_light .emoji_list {width: 310px;}
    .emoji_light .emoji_list_wrapper {background-color: #fff;}
    .emoji_light .emoji_list_wrapper::before {border-top: 10px solid #fff;}
    .emoji_light .tab_menu[data-v-fda2e876] {
    border-bottom: solid 1px #b1b1b1;
}
    .emoji_light .tab_menu .tab svg {
        fill: #898989;
        height: 18px;
    }
    .emoji_light .tab_menu .tab.active {
        border-bottom: solid 3px #333;
    }
    .emoji_light .tab_menu .tab.active svg {
        fill:#000;
    }

        /* Works on Firefox */
    .emoji_light .tab_content {
    scrollbar-width: thin;
    scrollbar-color: #dbdbdb;
    }
    /* Works on Chrome, Edge, and Safari */
    .emoji_light .tab_content::-webkit-scrollbar {
    width: 12px;
    }
    .emoji_light .tab_content::-webkit-scrollbar-track {
    background: #dbdbdb;
    }
    .emoji_light .tab_content::-webkit-scrollbar-thumb {
    background-color: #919191;
    border-radius: 20px;
    border: 3px solid #dbdbdb;
    }
    .emoji_light .tab_content::-webkit-scrollbar-thumb:hover {
    background-color: #8b8b8b;
    border: 3px solid #dbdbdb;
    }

</style>