import axios from 'axios';

const MAX_DESCR_POST = 500;

function likePost(post, emitter=false){
    let token = localStorage.getItem('token');
    if(token){
        axios.post(process.env.VUE_APP_URL+'like/post/'+ post.url, {}, {headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == 'OK'){
                if(post.islike){
                    post.islike = false;
                    post.likes--;
                }else{
                    post.islike = true;
                    post.likes++;
                }
            }
        });
    }
    else if(emitter){
        emitter.emit("showpopup",  { botones: true, msg: "Para poder dar like necesitas estar registrado"});
    }
}
function denunciarPost(urlpost, cb=false){
    let token = localStorage.getItem('token');
    if(token){
        axios.post(process.env.VUE_APP_URL+'denuncia/post/'+ urlpost, {}, {headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == 'OK'){
                if(rs.state == 'OK'){
                    if(cb) cb(true);
                    return true;
                }
            }
        })
    }
}

function eliminarPost(urlpost, cb=false){
    let token = localStorage.getItem('token');
    if(token){
        axios.post(process.env.VUE_APP_URL+'delete/post/'+ urlpost, {}, {headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == 'OK'){
                if(cb) cb(true);
                return true;
            }
        })
    }
}

function editPost(post, postEdit, emitter){
    let token = localStorage.getItem('token');
    if(post.descripcion.length > MAX_DESCR_POST){
        if(emitter){
            emitter.emit("showmsg", {clase: 'ko', msg: `El texto no puede ocupar mas de ${MAX_DESCR_POST} caracteres`, timeout:3000});
        }
        return;
    }
    if(token){
        axios.post(process.env.VUE_APP_URL+'update/post/'+ post.url, {"text":post.descripcion}, {headers: {"API-TOKEN": token}})
        .then(response => {
            var rs = response.data;
            if(rs.state == 'OK'){
                if(emitter){
                    emitter.emit("showmsg", {clase: 'ok', msg: "Post modificado con exito", timeout:3000});
                }
                postEdit.mediatext = post.descripcion;
                return true;
            }
        })
    }
}

function loadComentarios(url, cb){
    let token = localStorage.getItem('token');
    axios.get(`${process.env.VUE_APP_URL}comment/get/${url}`, {headers: {"API-TOKEN": token}})
    .then(response => {
        var rs = response.data;
        if(rs.state == "OK"){
            return cb(rs.data);
        }else{
            return cb([]);
        }
    })
}

function sendComentario(d, emitter, cb) {
    let token = localStorage.getItem('token');
    if(token){
        axios.post(`${process.env.VUE_APP_URL}comment/create/${d.u}`, {c: d.c, p:d.p}, {headers: {"API-TOKEN": token}})
        .then(response => {
            if(response.data.state == "OK")
                cb(response.data.data);
            else
                cb(false);
        })
    }else if(emitter){
        emitter.emit("showpopup",  { botones: true, msg: "¡Regístrate ahora para poder comentar!"});
    }
}

function likeComentario(id, emitter, cb){
    let token = localStorage.getItem('token');
    if(token){
        axios.post(`${process.env.VUE_APP_URL}comment/like/${id}`,{} ,{headers: {"API-TOKEN": token}})
        .then(response => {
            if(response.data.state == "OK")
                cb(true);
            else
                cb(false);
        })
    }else if(emitter){
        emitter.emit("showpopup",  { botones: true, msg: "Para poder dar like necesitas estar registrado"});
    }
}

function getWallPosts(data, cb){
    let token = localStorage.getItem('token');
    let headers = {headers: {"API-TOKEN": token}};
    let url = `${process.env.VUE_APP_URL}posts?1`;
    if(typeof data.n !== 'undefined')
        url+=`&n=${data.n}`;
    if(typeof data.l !== 'undefined')
        url+=`&l=${data.l}`;
    if(typeof data.tag !== 'undefined')
        url+=`&tag=${data.tag}`;
    if(typeof data.cm !== 'undefined')
        url+=`&cm=1`;

    axios.get(url, headers)
    .then(response => {
        var rs = response.data;
        if(rs.state == 'OK'){
            cb({"p": rs.data.data, "f": rs.data.fin, "c": rs.data.comments});
        }
    });
}

function getWallPost(url, cb){
    let token = localStorage.getItem('token');
    let headers = {headers: {"API-TOKEN": token}};
    axios.get( `${process.env.VUE_APP_URL}post/${url}`, headers)
    .then(response => {
        var rs = response.data;
        if(rs.state == 'OK'){
            cb(rs.data);
        }else{
            cb(false);
        }
    });
}

function deleteComment(id, cb)
{
    let token = localStorage.getItem('token');
    if(token){
        axios.post(`${process.env.VUE_APP_URL}comment/delete/${id}`,{}, {headers: {"API-TOKEN": token}})
        .then(response => {
            if(response.data.state == "OK")
                cb(true);
        })
    }
}

export { likePost, denunciarPost, eliminarPost, editPost, sendComentario, loadComentarios, likeComentario, getWallPosts, getWallPost, deleteComment, MAX_DESCR_POST };
