import axios from 'axios';

function viewPass(idStyle, idInput){
    let element = document.getElementById(idInput);
    let vp = document.getElementById(idStyle);
    if(element.type == "password"){
        element.type = "text";
        vp.classList.add("active");
    }else{
        element.type = "password";
        vp.classList.remove("active");
    }
}

function mb_length(str) {
    if(!str) str = "";
    var regexAstralSymbols = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
    return str.replace(regexAstralSymbols, '_').length;
}

function getBrow(){
    var hc = 0;
    if(navigator && navigator.hardwareConcurrency) hc = navigator.hardwareConcurrency;
    else hc = -1;
    return btoa(JSON.stringify({hc: hc, tz: Intl.DateTimeFormat().resolvedOptions().timeZone}));
}

function storePathView(path, store){
    let configAxios = {headers: {}}
    if(store.getters.isLogged){
        configAxios.headers = {"API-TOKEN": store.state.token};
    }
    axios.post(process.env.VUE_APP_URL+'trc/path', {
        p: path,
        br: getBrow()
    }, configAxios);
}


function storeEvent(event, store){
    let configAxios = {headers: {}}
    if(store.getters.isLogged){
        configAxios.headers = {"API-TOKEN": store.state.token};
    }
    axios.post(process.env.VUE_APP_URL+'sev', {
        e: event
    }, configAxios);
}

function timestampToString(datetime)
{
    let a = new Date(datetime);
    let year = a.getFullYear();
    let month = a.getMonth();
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let time = year + '/' + month + '/' + date + ' ' + hour + ':' + min + ':' + sec ;
    return time;
}

function openChatEmit(store, emitter){
    if(store.getters.wsConnect)
        emitter.emit("openchat", false);
    else
        emitter.emit("openchat_anon");
}

export { viewPass, mb_length, storePathView, storeEvent, timestampToString, openChatEmit };