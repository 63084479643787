
function changeTitle(title)
{
    document.title = title;
}

function changeMeta(key, value)
{
    document.querySelector(`meta[name='${key}']`).setAttribute("content", value);
}

// function reduceString(string, max)
// {
//     if (string.length > max){
//         let trimmedString = string.substr(0, max);
//         trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
//         return trimmedString;
//     }
//     return string;
// }
export { changeTitle, changeMeta };