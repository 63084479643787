<template>
    <footer>
        <div class="inner">
            <div class="footer_wrapper">
                <div class="footer_container">
                    <div class="footer_block ">
                        <nav class="footer_nav">
                            <ul>
                                <li><router-link to="/canales">Canales</router-link></li>
                                <li><router-link to="/mapa">Mapa</router-link></li>
                                <li><router-link to="/muro">Muro</router-link></li>
                                <li><router-link to="/mapitems/areasac">Areas de autocaravanas</router-link></li>
                            </ul>
                            <ul>
                                <li><router-link to="/contacto">Contacto</router-link></li>
                                <li><router-link to="/quienes_somos">Quienes somos</router-link></li>
                                <li><router-link to="/legal">Términos legales</router-link></li>
                            </ul> 
                        </nav>
                        <div class="profesional_link">
                            <a href="https://areaprofesionales.nomadas.tv" target="_blank" class="btn med white graphic_right mb20">Area de profesionales<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 17c2.269-9.881 11-11.667 11-11.667v-3.333l7 6.637-7 6.696v-3.333s-6.17-.171-11 5zm12 .145v2.855h-16v-12h6.598c.768-.787 1.561-1.449 2.339-2h-10.937v16h20v-6.769l-2 1.914z"/></svg> </a>
                        </div>
                    </div>
                    <div class="footer_block right">
                        <div class="contact_data">
                            <div class="contact_data_item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z"/></svg>660035617
                            </div>
                        </div>
                        <div class="social">
                            <a href="https://www.instagram.com/nomadastvoficial/" target="_blank">
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><path class="cls-1" d="M295.42,6c-53.2,2.51-89.53,11-121.29,23.48-32.87,12.81-60.73,30-88.45,57.82S40.89,143,28.17,175.92c-12.31,31.83-20.65,68.19-23,121.42S2.3,367.68,2.56,503.46,3.42,656.26,6,709.6c2.54,53.19,11,89.51,23.48,121.28,12.83,32.87,30,60.72,57.83,88.45S143,964.09,176,976.83c31.8,12.29,68.17,20.67,121.39,23s70.35,2.87,206.09,2.61,152.83-.86,206.16-3.39S799.1,988,830.88,975.58c32.87-12.86,60.74-30,88.45-57.84S964.1,862,976.81,829.06c12.32-31.8,20.69-68.17,23-121.35,2.33-53.37,2.88-70.41,2.62-206.17s-.87-152.78-3.4-206.1-11-89.53-23.47-121.32c-12.85-32.87-30-60.7-57.82-88.45S862,40.87,829.07,28.19c-31.82-12.31-68.17-20.7-121.39-23S637.33,2.3,501.54,2.56,348.75,3.4,295.42,6m5.84,903.88c-48.75-2.12-75.22-10.22-92.86-17-23.36-9-40-19.88-57.58-37.29s-28.38-34.11-37.5-57.42c-6.85-17.64-15.1-44.08-17.38-92.83-2.48-52.69-3-68.51-3.29-202s.22-149.29,2.53-202c2.08-48.71,10.23-75.21,17-92.84,9-23.39,19.84-40,37.29-57.57s34.1-28.39,57.43-37.51c17.62-6.88,44.06-15.06,92.79-17.38,52.73-2.5,68.53-3,202-3.29s149.31.21,202.06,2.53c48.71,2.12,75.22,10.19,92.83,17,23.37,9,40,19.81,57.57,37.29s28.4,34.07,37.52,57.45c6.89,17.57,15.07,44,17.37,92.76,2.51,52.73,3.08,68.54,3.32,202s-.23,149.31-2.54,202c-2.13,48.75-10.21,75.23-17,92.89-9,23.35-19.85,40-37.31,57.56s-34.09,28.38-57.43,37.5c-17.6,6.87-44.07,15.07-92.76,17.39-52.73,2.48-68.53,3-202.05,3.29s-149.27-.25-202-2.53m407.6-674.61a60,60,0,1,0,59.88-60.1,60,60,0,0,0-59.88,60.1M245.77,503c.28,141.8,115.44,256.49,257.21,256.22S759.52,643.8,759.25,502,643.79,245.48,502,245.76,245.5,361.22,245.77,503m90.06-.18a166.67,166.67,0,1,1,167,166.34,166.65,166.65,0,0,1-167-166.34" transform="translate(-2.5 -2.5)"/></svg>
                            </a>
                            <a href="https://www.youtube.com/@nomadastv411" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/></svg>
                            </a>
                            <a href="https://www.facebook.com/nomadastvoficial/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/></svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="footer_container">
                    <div class="footer_block ">
                        <div class="footer_logo">
                            <router-link to="/"><img src="@/assets/images/nomadas_tv_logo.png" alt="nomadas.tv"></router-link>
                        </div>
                    </div>
                    <div class="footer_block right">
                        <div class="legal_logo">
                            <a href="" target="_blank" tite="Plataforma por el Streaming">
                                <img src="@/assets/images/plataforma_streaming_logo.png" alt="Plataforma por el Streaming">
                            </a>
                            <a href="https://www.cultura.gob.es/destacados/bono-cultural-joven.html" target="_blank" title="Ministerio de Cultura y Deporte - Bono Joven Cultural">
                                <img src="@/assets/images/ministerio_cultura_bono_joven_logo.png" alt="Ministerio de Cultura y Deporte - Bono Joven Cultural">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="legal_wrapper">
                <div class="legal_text">
                    <p>© INVESTIGACIÓN y DESARROLLO de IDEAS y PROYECTOS S.L. 2022</p>
                    <p>Intranet 2022 Investigación y Desarrollo de Ideas y Proyectos S.L. Todos los derechos reservados. Cualquier copia o reproducción, total o parcial de los contenidos de esta web, está totalmente prohibido sin consentimiento expreso. Todos los precios de la web llevan IVA.</p>
                    <p>Investigación y Desarrollo de Ideas y Proyectos S.L., <a href="mailto:proyecto@nomadas.tv">proyecto@nomadas.tv</a>,  CIF: B12895017 , Hurtada 31, 28240 Madrid, Registro Mercantil de Madrid Tomo 3133, Folio 123, Sección 8, Hoja M-563964, Inscripción 2, fecha 11 de Noviembre de 2013.</p>
                    <p>El órgano regulador de la plataforma es La Comisión Nacional de los Mercados y la Competencia | CNMC.</p>
                    <p><router-link to="/legal">Términos legales</router-link></p>
                </div>
                <div class="legal_logo">
                    
                    <a href="https://faudiovisual.com/" target="_blank" class="legal_logo main_logo" title="Powered By Factoría Audiovisual">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 390 80" style="enable-background:new 0 0 390 80;" xml:space="preserve">
                    <polygon class="st0" points="179.1,62.5 192.1,62.5 185.6,73.8 172.6,73.8 " style="fill:#138C9F"/>
                    <polygon class="st0" points="153.1,62.5 166.1,62.5 159.6,73.8 146.6,73.8 " style="fill:#138C9F"/>
                    <polygon class="st0" points="133.6,51.3 120.5,73.8 133.6,73.8 140.1,62.5 " style="fill:#138C9F"/>
                    <polygon class="st0" points="146.6,28.7 142.2,36.2 140.1,40 153.1,40 153.1,40 " style="fill:#138C9F"/>
                    <polygon class="st1" points="114,62.5 120.5,73.8 133.6,51.3 146.6,73.8 153.1,62.5 140.1,40 146.6,28.7 172.6,73.8 179.1,62.5 
                        146.6,6.2 " style="fill:#52B9CC"/>
                    <polygon class="st2" points="146.6,6.2 159.6,6.2 192.1,62.5 179.1,62.5 " style="fill:#B9E0ED"/>
                    <polygon class="st2" points="140.1,40 153.1,62.5 166.1,62.5 153.1,40 " style="fill:#B9E0ED"/>
                    <path class="st3" d="M209.2,10.3h17.1v4.5h-11.8v5.6h10.2v4.2h-10.2v10.1h-5.4V10.3z M227.9,22.4c0.3-4.5,4.3-5.9,8.3-5.9
                        c3.5,0,7.8,0.8,7.8,5v9.2c0,1.6,0.2,3.2,0.6,3.9h-4.9c-0.2-0.5-0.3-1.1-0.3-1.7c-1.5,1.6-3.8,2.2-5.9,2.2c-3.4,0-6-1.7-6-5.3
                        c0-4,3-5,6-5.4c3-0.4,5.7-0.3,5.7-2.3c0-2.1-1.4-2.4-3.1-2.4c-1.8,0-3,0.8-3.2,2.7L227.9,22.4L227.9,22.4z M239.1,26
                        c-0.8,0.7-2.5,0.8-4,1c-1.5,0.3-2.9,0.8-2.9,2.6c0,1.8,1.4,2.2,3,2.2c3.8,0,3.9-3,3.9-4.1V26z M258.8,23.2c-0.3-2-1.6-3-3.6-3
                        c-3.1,0-4.1,3.1-4.1,5.7c0,2.5,1,5.6,4,5.6c2.2,0,3.6-1.4,3.9-3.6h4.7c-0.6,4.7-3.9,7.2-8.5,7.2c-5.3,0-8.9-3.8-8.9-9.1
                        c0-5.5,3.3-9.6,9-9.6c4.2,0,8,2.2,8.3,6.7C263.6,23.2,258.8,23.2,258.8,23.2z M271.7,17h3.6v3.2h-3.6V29c0,1.6,0.4,2,2.1,2
                        c0.5,0,1,0,1.5-0.1v3.8c-0.8,0.1-1.9,0.2-2.8,0.2c-3,0-5.6-0.7-5.6-4.2V20.2h-2.9V17h2.9v-5.3h4.9L271.7,17L271.7,17z M285.3,16.5
                        c5.6,0,9.1,3.7,9.1,9.3c0,5.6-3.6,9.3-9.1,9.3c-5.5,0-9.1-3.7-9.1-9.3C276.2,20.2,279.8,16.5,285.3,16.5z M285.3,31.5
                        c3.3,0,4.3-2.8,4.3-5.6c0-2.8-1-5.7-4.3-5.7c-3.3,0-4.3,2.8-4.3,5.7C281,28.6,282,31.5,285.3,31.5z M296.7,17h4.6v3.3h0.1
                        c0.9-2.2,3.3-3.8,5.6-3.8c0.3,0,0.8,0.1,1.1,0.2v4.5c-0.4-0.1-1.2-0.2-1.7-0.2c-3.5,0-4.8,2.6-4.8,5.7v8h-4.9V17z M312.5,14.8h-3.4
                        l3.3-4.9h5.4L312.5,14.8z M309.3,17h4.9v17.6h-4.9V17z M316.9,22.4c0.3-4.5,4.3-5.9,8.3-5.9c3.5,0,7.8,0.8,7.8,5v9.2
                        c0,1.6,0.2,3.2,0.6,3.9h-4.9c-0.2-0.5-0.3-1.1-0.3-1.7c-1.5,1.6-3.8,2.2-5.9,2.2c-3.4,0-6-1.7-6-5.3c0-4,3-5,6-5.4
                        c3-0.4,5.7-0.3,5.7-2.3c0-2.1-1.4-2.4-3.1-2.4c-1.8,0-3,0.8-3.2,2.7L316.9,22.4L316.9,22.4z M328.1,26c-0.8,0.7-2.5,0.8-4,1
                        c-1.5,0.3-2.9,0.8-2.9,2.6c0,1.8,1.4,2.2,3,2.2c3.8,0,3.9-3,3.9-4.1V26z M215.8,44.4h5.5l9.1,24.4h-5.6l-1.8-5.4h-9.1l-1.9,5.4h-5.4
                        L215.8,44.4z M215.3,59.4h6.3l-3.1-8.9h-0.1L215.3,59.4z M247.8,68.8h-4.6v-2.5h-0.1c-1.2,2-3.4,2.9-5.4,2.9c-5.2,0-6.4-2.9-6.4-7.3
                        V51.1h4.8v10c0,2.9,0.9,4.3,3.1,4.3c2.6,0,3.8-1.5,3.8-5v-9.2h4.9L247.8,68.8L247.8,68.8z M263.2,66.5L263.2,66.5
                        c-1.2,1.9-3.2,2.7-5.4,2.7c-5.3,0-7.9-4.6-7.9-9.5c0-4.8,2.6-9.1,7.8-9.1c2.1,0,4.1,0.9,5.2,2.6h0.1v-8.9h4.9v24.4h-4.6L263.2,66.5
                        L263.2,66.5z M259,54.3c-3.2,0-4.3,2.7-4.3,5.6c0,2.8,1.3,5.7,4.3,5.7c3.2,0,4.2-2.8,4.2-5.7C263.1,57,262.1,54.3,259,54.3z
                        M275.6,48.4h-4.9v-4h4.9V48.4z M270.8,51.1h4.9v17.6h-4.9V51.1z M287.1,50.6c5.6,0,9.1,3.7,9.1,9.3c0,5.6-3.6,9.3-9.1,9.3
                        c-5.5,0-9.1-3.7-9.1-9.3C278,54.3,281.6,50.6,287.1,50.6z M287.1,65.6c3.3,0,4.3-2.8,4.3-5.6c0-2.8-1-5.7-4.3-5.7
                        c-3.3,0-4.3,2.8-4.3,5.7C282.9,62.8,283.9,65.6,287.1,65.6z M308.3,68.8H303l-6-17.7h5.1l3.7,12h0.1l3.7-12h4.8L308.3,68.8z
                        M320.5,48.4h-4.9v-4h4.9V48.4z M315.6,51.1h4.9v17.6h-4.9V51.1z M327.2,63c0,2.1,1.8,3,3.7,3c1.4,0,3.2-0.6,3.2-2.3
                        c0-1.5-2-2-5.5-2.7c-2.8-0.6-5.6-1.6-5.6-4.7c0-4.5,3.9-5.7,7.7-5.7c3.9,0,7.4,1.3,7.8,5.6h-4.6c-0.1-1.9-1.6-2.4-3.3-2.4
                        c-1.1,0-2.7,0.2-2.7,1.6c0,1.7,2.7,2,5.5,2.6c2.8,0.7,5.6,1.7,5.6,5c0,4.6-4,6.2-8.1,6.2c-4.1,0-8.1-1.5-8.3-6.2L327.2,63L327.2,63z
                        M357.5,68.8h-4.6v-2.5h-0.1c-1.2,2-3.4,2.9-5.4,2.9c-5.2,0-6.4-2.9-6.4-7.3V51.1h4.9v10c0,2.9,0.9,4.3,3.1,4.3c2.6,0,3.8-1.5,3.8-5
                        v-9.2h4.9L357.5,68.8L357.5,68.8z M360.1,56.6c0.3-4.5,4.3-5.9,8.3-5.9c3.5,0,7.7,0.8,7.7,5v9.2c0,1.6,0.2,3.2,0.6,3.9h-4.9
                        c-0.2-0.6-0.3-1.1-0.3-1.7c-1.5,1.6-3.8,2.2-5.9,2.2c-3.4,0-6-1.7-6-5.3c0-4,3-5,6-5.4c3-0.4,5.7-0.3,5.7-2.3c0-2.1-1.4-2.4-3.1-2.4
                        c-1.8,0-3,0.8-3.2,2.7L360.1,56.6L360.1,56.6z M371.3,60.1c-0.8,0.7-2.5,0.8-4,1c-1.5,0.3-2.9,0.8-2.9,2.6c0,1.8,1.4,2.2,3,2.2
                        c3.8,0,3.9-3,3.9-4.1V60.1z M379.2,44.4h4.9v24.4h-4.9V44.4z" style="fill:#fff"/>
                    <path d="M6,18.6h6.9c4.5,0,5.3,2.9,5.3,4.9c0,1.9-0.9,4.8-5.3,4.8H8.7v6H6V18.6z M8.7,26h4c1.2,0,2.8-0.6,2.8-2.6
                        c0-2.1-1.3-2.6-2.7-2.6H8.7V26z M19.5,28.6c0-3.5,2.1-6,5.7-6c3.7,0,5.7,2.5,5.7,6c0,3.5-2.1,6-5.7,6C21.6,34.6,19.5,32.1,19.5,28.6
                        z M28.5,28.6c0-2-1-4-3.2-4c-2.2,0-3.2,2-3.2,4c0,2,1,4,3.2,4C27.5,32.6,28.5,30.5,28.5,28.6z M31.8,22.9h2.7l2.2,8.5h0l2.1-8.5h2.5
                        l2,8.5h0l2.3-8.5h2.6l-3.6,11.4h-2.6l-2.1-8.4h0L38,34.3h-2.6L31.8,22.9z M51.7,29.2c0,1.8,1,3.4,3.1,3.4c1.5,0,2.3-0.6,2.8-1.9h2.4
                        c-0.6,2.5-2.7,3.9-5.1,3.9c-3.6,0-5.6-2.5-5.6-6c0-3.2,2.1-6,5.5-6c3.6,0,5.8,3.2,5.4,6.6H51.7z M57.6,27.6c-0.1-1.6-1.2-3-2.9-3
                        c-1.7,0-2.9,1.3-3,3H57.6z M61.8,22.9h2.4v2.2h0c0.3-1.2,1.8-2.5,3.3-2.5c0.6,0,0.7,0,0.9,0.1v2.4c-0.4,0-0.7-0.1-1.1-0.1
                        c-1.7,0-3.1,1.4-3.1,3.9v5.4h-2.5V22.9z M70.9,29.2c0,1.8,1,3.4,3.1,3.4c1.5,0,2.3-0.6,2.8-1.9h2.4c-0.6,2.5-2.7,3.9-5.1,3.9
                        c-3.6,0-5.6-2.5-5.6-6c0-3.2,2.1-6,5.5-6c3.6,0,5.8,3.2,5.4,6.6H70.9z M76.7,27.6c-0.1-1.6-1.2-3-2.9-3c-1.7,0-2.9,1.3-3,3H76.7z
                        M91.6,34.3h-2.4v-1.5h0c-0.7,1.3-2.1,1.8-3.5,1.8c-3.6,0-5.3-2.7-5.3-6.1c0-4.1,2.4-5.9,4.9-5.9c1.4,0,3,0.5,3.8,1.8h0v-5.8h2.5
                        V34.3z M86,32.6c2.2,0,3.2-2,3.2-4c0-2.5-1.2-4-3.1-4c-2.4,0-3.2,2.1-3.2,4.1C82.9,30.7,83.9,32.6,86,32.6z M69.8,41.6h2.5v5.8h0
                        c0.7-1.1,2.1-1.8,3.4-1.8c3.6,0,5.3,2.7,5.3,6.1c0,3.1-1.5,5.9-4.9,5.9c-1.5,0-3.2-0.4-3.9-1.9h0v1.6h-2.4V41.6z M75.4,47.6
                        c-2.1,0-3.2,1.6-3.2,4c0,2.3,1.2,4,3.2,4c2.3,0,3.1-2,3.1-4C78.6,49.5,77.5,47.6,75.4,47.6z M81.7,45.9h2.8l3,8.5h0l2.9-8.5H93
                        l-4.4,12c-0.8,2-1.4,3.9-4,3.9c-0.6,0-1.2,0-1.7-0.1v-2.1c0.4,0.1,0.8,0.2,1.2,0.2c1.2,0,1.5-0.6,1.8-1.6l0.3-0.9L81.7,45.9z" style="fill:#fff"/>
                    </svg>

                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>