<template>
    <div>
        <p class="wall_item_desc">
            <router-link :to="`/perfil/${props.username}`" class="user_link">{{props.username}}</router-link>
            <template v-for="t, index in textParts" :key="index">
                <template  v-if="t.type == 'text'">{{t.text}}</template>
                <router-link v-if="t.type == 'route'" :to="t.to">{{t.text}}</router-link>
            </template>
        </p>
    </div>
</template>
<script setup>
    import { ref, onMounted, watch } from 'vue';
    const props = defineProps({
        text: {
            type: String
        },
        username: {
            type: String
        },
        activeHashtags: {
            type: Boolean,
            default: true
        }
    });

    const textParts = ref([]);
    onMounted(() => {
        setText(props.text);
    });
    watch(() => props.text, (text) => {
        setText(text);
    });

    const setText = (text) => {
        let parts;
        textParts.value = [];
        parts = text.split(/(#\w{0,50}|@[a-z0-9_]{0,50})/);
        for(let i=0; i < parts.length; i++){
            if(props.activeHashtags && parts[i].startsWith("#"))
                textParts.value.push({type: "route", text: parts[i],  to: `/tags/${parts[i].substring(1)}`});
            else if(parts[i].startsWith("@"))
                textParts.value.push({type: "route", text: parts[i],  to: `/perfil/${parts[i].substring(1)}`});
            else
                textParts.value.push({type: "text", text: parts[i]});
        }
    }

 
</script>

<style scoped>
    p.wall_item_desc {font-family: 'Open sans', sans-serif;font-size: 13px;margin-bottom: 0;word-break: break-word;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
    .wall_item_desc a {color: #9d9d9d;}
    .wall_item_desc a:hover {color: #000;text-decoration: underline;}
    a.user_link {color: #000;font-weight: bold;margin-right: 5px;}
</style>
