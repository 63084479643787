import { createRouter, createWebHistory } from 'vue-router'
import MuroView from '@/views/MuroView.vue';

const routes = [
    {
        path: '/',
        name: 'landing',
        component: () => import(/* webpackChunkName: "landing" */ '../views/LandingView.vue')
    },
    {
        path: '/mapa/location/:urlpoint',
        name: 'mapaFicha',
        component: () => import(/* webpackChunkName: "mapa" */ '../views/MapView.vue')
    },
    {
        path: '/mapa',
        name: 'mapa',
        component: () => import(/* webpackChunkName: "mapa" */ '../views/MapView.vue')
    },
    {
        path: '/perfil',
        name: 'miPerfil',
        component: () => import(/* webpackChunkName: "miPerfil" */ '../views/MiPerfilView.vue')
    },
    {
        path: '/perfil/:name',
        name: 'perfil',
        component: () => import(/* webpackChunkName: "perfil" */ '../views/PerfilView.vue')
    }, 
    {
        path: '/legal',
        name: 'legal',
        component: () => import(/* webpackChunkName: "legal" */ '../views/TerminosLegales.vue')
    },
    {
        path: '/nuevopass/:code',
        name: 'nuevopass',
        component: () => import(/* webpackChunkName: "nuevopass" */ '../views/NuevoPassword.vue')
    },
    {
        path: '/canales',
        name: 'canales',
        component: () => import(/* webpackChunkName: "canales" */ '../views/CanalesView.vue')
    },
    {
        path: '/canales/:name',
        name: 'canal',
        component: () => import(/* webpackChunkName: "canal" */ '../views/CanalView.vue')
    },
    {
        path: '/canales/:name/:videoname',
        name: 'VideoCanal',
        component: () => import(/* webpackChunkName: "VideoCanal" */ '../views/VideoCanalView.vue')
    },
    {
        path: '/verificar/:code',
        name: 'verificar',
        component: () => import(/* webpackChunkName: "verificar" */ '../views/ActivarCuenta.vue')
    },
    {
        path: '/contacto',
        name: 'contacto',
        component: () => import(/* webpackChunkName: "contacto" */ '../views/ContactoView.vue')
    },
    {
        path: '/premium',
        name: 'premium',
        component: () => import(/* webpackChunkName: "premium" */ '../views/PremiumView.vue')
    },
    {
        path: '/muro/:post?',
        name: 'muro',
        component: MuroView
    },{
        path: '/tags/:tag',
        name: 'posttag',
        component: () => import(/* webpackChunkName: "muro" */ '../views/PostsHashtagView.vue')
    },
    {
        path: '/subscribe/:canal',
        name: 'subscripcioncanal',
        component: () => import(/* webpackChunkName: "muro" */ '../views/SubscriptionCanal.vue')
    },
    //{
    //     path: '/mapitems/areasac/:keyword?/:value?',
    //     name: 'areasauto',
    //     component: () => import(/* webpackChunkName: "muro" */ '../views/AreasAuto.vue')
    // },
    {
        path: '/mapitems/areasac/:keyword?/:value?',
        name: 'areasauto',
        redirect: '/mapa'
    },
    {
        path: '/SnLz0Ed2Mp_qd4ZZYMLOwOTY8C76KhBiNtVvbqER',
        name: 'mapaastorga',
        redirect: '/mapa/location/Fgl8suenHBikWWSxywA36maIvV2TaW1705667722'
    },
    {
        path: '/msmwyjmampnewoqnb2odu2qa_lkxpaesyy7wujbow',
        name: 'mapaponferrada',
        redirect: '/mapa/location/dwvfoTj03mJMBuCQuYLOGewe78u9lu1705912957'
    },
    {
        path: '/quienes_somos',
        name: 'quienesSomos',
        component: () => import(/* webpackChunkName: "quienesSomos" */ '../views/QuienesSomos.vue')
    },
    {
        path: "/:pathMatch(.*)*",
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404View.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
