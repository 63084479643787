<template>
    <div>
        <transition name="fade">  
            <LoginRegister ref="loginRegisterPopUp"/>
        </transition>
        <transition name="fade">   
            <MyPopup :msg_popup="msg_popup"/>
        </transition>
        <transition name="fade">
            <MyMensaje :msg_pet="msg_pet"/>
        </transition>
        <transition name="fade">
            <BannerHome :displayIn="2"/>
        </transition>
        <MyCookies/>
        <MyChat v-if="this.store.getters.wsConnect && this.store.getters.isLogged"/>
        <AnonChat v-else-if="!this.store.getters.isLogged"/>
        <MyHeader/>
        <transition name="fade">
            <div v-if="loading" class="curtain">
                <div class="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </transition>
        <router-view/>  
        <MyFooter/>
    </div>
</template>


<script>
    import MyCookies from '@/components/MyCookies.vue';
    import MyPopup from '@/components/MyPopup.vue';
    import MyMensaje from '@/components/MyMensaje.vue';
    import MyChat from '@/components/MyChat.vue';
    import AnonChat from '@/components/AnonChat.vue';
    import MyHeader from '@/components/MyHeader.vue';
    import MyFooter from '@/components/MyFooter.vue';
    import BannerHome from '@/components/BannerHome.vue';
    import LoginRegister from '@/components/LoginRegister.vue';
    import { inject } from 'vue';
    import { useStore } from 'vuex';
    import { storePathView } from '@/composables/varios.js';
    export default {
        name: "App",
        components: {
            MyCookies,
            MyPopup,
            MyChat,
            MyHeader,
            MyMensaje,
            MyFooter,
            AnonChat,
            BannerHome,
            LoginRegister
        },
        data: function(){
            return{
                loading: false,
                msg_pet: {},
                msg_popup: {},
                store: useStore(),
                positionInterval: false
            }
        },
        mounted() {
            const emitter = inject("emitter");
            emitter.on("loaded", () => this.loading=false);
            emitter.on("loading", () => this.loading=true);
            emitter.on("showmsg", (m) => this.msg_pet=m);
            emitter.on("showpopup", (m) => this.msg_popup=m);
            emitter.on("popUpLogin", (mode)=>{ this.$refs.loginRegisterPopUp.showModal(mode); })
        },
        watch:{
            $route (to){
                if(to.fullPath.startsWith("/mapa"))
                    document.getElementsByTagName("footer")[0].classList.add("map_footer");
                else
                    document.getElementsByTagName("footer")[0].classList.remove("map_footer")

                storePathView(to.fullPath, this.store);
            }
        }
    }
  
</script>
