<template>
    <div class="popup_wrapper" v-if="showPopUp">
        <div class="popup_window">
            <div class="popup_content home_popup">
                <div class="close_btn" @click="() => {showPopUp = false}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"/></svg></div>
                <h2 class="popup_heading">"Descubre, Planifica, Disfruta: Tu Viaje Perfecto Comienza Aquí"</h2>
                <div class="popup_ad_image">
                    <div class="popup_ad_image_text">
                        <h2>Regístrate ahora y descarga la guía:</h2>
                        <h3><span>10</span>joyas<br/>turísticas</h3>
                        <p>Explora España con 10 joyas turísticas planificadas para tu viaje perfecto</p>
                    </div>
                    <div class="popup_ad_image_img">
                        <img src="@/assets/images/nomadas_tv_guia_interactiva_10_sitios_BIG.png" alt="Guia intercativa Nómadas Tv - 'Los 10 sitios que como nómada no puedes perderte'">
                    </div>
                </div>
                <div class="popup_nav" v-if="!store.getters.isLogged">
                    <div @click="emitter.emit('popUpLogin', 1); showPopUp = false;" class="btn med white graphic_right">Ir a Login<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                    <div @click="emitter.emit('popUpLogin', 2); showPopUp = false;" class="btn med white graphic_right">Ir al registro<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                </div>
                
                <div class="popup_nav" v-else>
                    <div @click="goToAndClose('/perfil')" class="btn med white wide graphic_right">Ir a perfil<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref, inject } from 'vue';
    import { useRouter } from "vue-router";
    import { useStore } from 'vuex';

    const router = useRouter();
    const store = useStore();
    const showPopUp = ref(false);
    const emitter = inject("emitter");
    const props = defineProps({
        displayIn: {
            type: Number,
            default: 0
        }
    });
    onMounted(()=>{
        if(store.getters.bannerHomeTimeVisible){
            setTimeout(()=>{
                showPopUp.value = true;
            }, props.displayIn * 1000);
        }
    });

    const goToAndClose = (to)=>{
        showPopUp.value = false;
        router.push(to);
    }
 
</script>

<style scoped>

</style>
