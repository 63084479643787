<template>
    <div class="chat_wrapper">
        <div id="chat_trigger" class="chat_trigger visible" @click="openChat">
            <div v-if="numChatsNoVistos > 0" class="chats_baloon">{{numChatsNoVistos}}</div>
            <div class="chat_trigger_btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 9.352c0-4.852-4.75-8.352-10-8.352-5.281 0-10 3.527-10 8.352 0 1.71.615 3.39 1.705 4.695.047 1.527-.85 3.719-1.66 5.312 2.168-.391 5.252-1.258 6.648-2.115 7.698 1.877 13.307-2.842 13.307-7.892zm-14.5 1.381c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm7.036 1.441c-.161.488-.361.961-.601 1.416 1.677 1.262 2.257 3.226.464 5.365-.021.745-.049 1.049.138 1.865-.892-.307-.979-.392-1.665-.813-2.127.519-4.265.696-6.089-.855-.562.159-1.145.278-1.74.364 1.513 1.877 4.298 2.897 7.577 2.1.914.561 2.933 1.127 4.352 1.385-.53-1.045-1.117-2.479-1.088-3.479 1.755-2.098 1.543-5.436-1.348-7.348z"></path></svg>
                <span>Chatea con<br/>otros nomadas</span>
            </div>
        </div>
        <div id="chatdiv" class="chat content_block boxed dynamic__container container__right">
            <div class="chat_header bordered">
                <h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 9.352c0-4.852-4.75-8.352-10-8.352-5.281 0-10 3.527-10 8.352 0 1.71.615 3.39 1.705 4.695.047 1.527-.85 3.719-1.66 5.312 2.168-.391 5.252-1.258 6.648-2.115 7.698 1.877 13.307-2.842 13.307-7.892zm-14.5 1.381c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm4.5 0c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25zm7.036 1.441c-.161.488-.361.961-.601 1.416 1.677 1.262 2.257 3.226.464 5.365-.021.745-.049 1.049.138 1.865-.892-.307-.979-.392-1.665-.813-2.127.519-4.265.696-6.089-.855-.562.159-1.145.278-1.74.364 1.513 1.877 4.298 2.897 7.577 2.1.914.561 2.933 1.127 4.352 1.385-.53-1.045-1.117-2.479-1.088-3.479 1.755-2.098 1.543-5.436-1.348-7.348z"/></svg>Chat
                </h3>
                <div @click="closeChat" class="block_header_menu_trigger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.024 4h6.015l7.961 8-7.961 8h-6.015l7.961-8-7.961-8zm-10.024 16h6.015l7.961-8-7.961-8h-6.015l7.961 8-7.961 8z"/></svg>
                </div>
            </div>
            <div class="chat_container">
                <div id="actual_chat" class="actual_chat">
                    <template v-if="actualChat">
                        <div class="chat_user">
                            <div @click="closeChat" class="back_button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                            <div class="chat_user_photo" :title="actualChat.name">
                                <img :src="actualChat.img" :alt="actualChat.name" width="34" height="34">
                            </div>
                            <div class="chat_user_name">{{ actualChat.name }}</div>
                        </div>
                        <div class="conversation">
                            <template v-for="data,index in actualChat.chats" :key="index">
                                <span :class="data.send ? 'sender_balloon' : 'receiver_balloon'" >{{data.msg}}<span class="message_date">{{data.time}}</span></span>
                                <div v-if="index == actualChat.chats.length -1 || (actualChat.chats[index + 1].date !== data.date)" class="chat_separator_info">
                                    <span>{{data.date}} </span>
                                </div>
                            </template>
                        </div>
                        <div class="btn med grey graphic_right" @click="()=>{ emitter.emit('popUpLogin', 2); }" style="border-radius: 0;">
                            Regístrate<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, inject } from 'vue';
    import { storeEvent } from '@/composables/varios.js';
    import { useStore } from 'vuex';
    const store = useStore();
    const numChatsNoVistos = ref(1);
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio","Julio", "Augosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let date = new Date();
    let dateString = date.getDate() +" de "+monthNames[date.getMonth()];
    const chatMessage = [
        {send: false, date: dateString, msg: "Regístrate y comienza tu aventura."},
        {send: false, date: dateString, msg: "¿Quieres ponerte en contacto con otros nómadas?"},
        {send: false, date: dateString, msg: "¡Hola nómada!"},
    ];
    const actualChat = { name: "nomadastv", chats: chatMessage, img: "https://imgdb.nomadas.tv/perfil/1660308581G93IEEjxw7TOKVSMIsjM.jpg"};
    const emitter = inject('emitter');


    const openChat = () => {
        if(numChatsNoVistos.value == 1){
            numChatsNoVistos.value = 0;
        }
        storeEvent(2, store);
        document.getElementById("chat_trigger").classList.remove("visible");
        document.getElementById("chatdiv").classList.add("visible");
        actualChat.value = { name: "nomadastv", chats: chatMessage, img: "https://imgdb.nomadas.tv/perfil/1660308581G93IEEjxw7TOKVSMIsjM.jpg", block: false  } ;
        document.getElementById("actual_chat").classList.add("visible");

    }

    const closeChat = () => {
        document.getElementById("chat_trigger").classList.add("visible");
        document.getElementById("chatdiv").classList.remove("visible");
    }

    emitter.on("openchat_anon", () => {
        openChat();
    });



</script>

<style scoped>
/*----------------------------------------------------------------------------------- 
CHAT
-----------------------------------------------------------------------------------*/
.chat {background-color: #585858;font-family: 'Open sans', sans-serif;position: fixed;top: 60px;right: 0;bottom: 20px;z-index: 99;width: 100%;overflow: hidden;z-index: 9999;
    box-shadow: 0px 3px 5px 0px rgb(0 0 0 / 30%);
}
.chat .chat_header {background-color: #f9b238;border-bottom: solid 1px #5d5d5d;
	display: flex;
	justify-content: space-between;
}
.chat .chat_header h3 {color: #000;font-size: 16px;line-height: 16px;padding: 10px 12px;}
.chat .chat_header h3 svg {fill: #000;width: 16px;height: 16px;margin-right: 7px;vertical-align: top;}
.chat .block_header_menu_trigger svg {fill: #000;}
.chat .block_header_menu_trigger:hover {background-color: #ffcb6d;}
.chat_window {position: relative;height: calc(100vh - 197px);overflow-y: auto;overflow-x: hidden;}
.chat_container {height: calc(100% - 37px);position: relative;}
.chat_container .chat_tabs {border-bottom: solid 1px #424242;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}
.chat_container .chat_tabs .chat_tab {width: 100%;padding: 10px 10px 6px 10px;
    display: flex;
    justify-content: center;
}
.chat_container .chat_tabs .chat_tab {border-bottom: solid 4px #585858;background-color: #585858;cursor: pointer;color: #b9b9b9;font-size: 14px;position: relative;}
.chat_container .chat_tabs .chat_tab:hover, .chat_container .chat_tabs .chat_tab.tab_active {background-color: #6e6e6e;}
.chat_container .chat_tabs .chat_tab.tab_active {border-color: #03d117;}
.chat_container .chat_tabs .chat_tab svg {fill: #b9b9b9;margin-right: 10px;width: 18px;height: 18px;}
.chat_container .chat_tabs .chat_tab:hover {border-color: #fff;}
.chat_container .chat_tabs .chat_tab:hover, .chat_container .chat_tabs .chat_tab.tab_active {color: #fff;}
.chat_container .chat_tabs .chat_tab:hover svg, .chat_container .chat_tabs .chat_tab.tab_active svg {fill: #fff;}
.chat_container .chat_tabs .chat_tab.tab_active:hover {border-color: #03d117;}
.chats_wrapper {position: relative;width: 100%;height: 100%;}
.chat_window .chats_list {width: 100%;height: 100%;position: absolute;top: 0;overflow-y: auto;
    -webkit-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -moz-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -o-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -ms-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
}
/* .chat_window .chats_list.chats_history {background-color: #f60;}*/
/* .chat_window .chats_list.chats_friends {background-color: #fF0;}*/
.chats_wrapper.tab_01_selected .chats_list.chats_history {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.chats_wrapper.tab_01_selected .chats_list.chats_friends {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}
.chats_wrapper.tab_02_selected .chats_list.chats_history {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}
.chats_wrapper.tab_02_selected .chats_list.chats_friends {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.chat_window .chats_list .chats_list_item {border-bottom: solid 1px #707070;cursor: pointer;display: flex;padding: 15px 10px;position: relative;}
.chat_window .chats_list .chats_list_item:hover {background-color: #676767;}
.chat_window .chats_list .chats_list_item.user_blocked, .chat_window .chats_list .chats_list_item.user_blocked:hover {background-color: #ba4f4f;}
.chat_window .chats_list .chats_list_item .chat_user_photo {overflow: hidden;margin-right: 10px;width: 40px;border-radius: 40px;}
.chat_window .chats_list .chats_list_item .chat_user_last_date {color: #fff;font-size: 12px;position: absolute;right: 10px;}
.chat_window .chats_list .chats_list_item .chat_user_name {color: #fff;font-size: 14px;margin-bottom: 3px;font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.chat_window .chats_list .chats_list_item .chats_baloon {background-color: #22a535;color: #fff;font-size: 13px;font-weight: 500;position: absolute;right: 10px;bottom: 15px;width: 20px;height: 20px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat_window .chats_list .chats_list_item .chat_user_last_chat {color: #d1d1d1;font-size: 12px;font-weight: 400;max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.chat_container .actual_chat {background-color: #585858;position: absolute;width: 100%;height: 100%;z-index: 10;left: 0;top: 0;
    display: flex;
    flex-direction: column;
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    -webkit-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -moz-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -o-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -ms-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
}
.chat_container .actual_chat.visible {
    -webkit-transform: none;
    transform: none;
}
.chat_container .actual_chat .chat_user {overflow: hidden;padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
        align-items: center;
}
.chat_container .actual_chat .chat_user.user_blocked {background-color: #ba4f4f;}
.chat_container .actual_chat .chat_user .back_button {cursor: pointer;margin-right: 10px;width: 18px;height: 18px;}
.chat_container .actual_chat .chat_user .back_button svg {fill: #fff;width: 18px;height: 18px;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.chat_container .actual_chat .chat_user .chat_user_photo {overflow: hidden;margin-right: 10px;width: 35px;border-radius: 35px;}
.chat_container .actual_chat .chat_user .chat_user_last_date {color: #fff;font-size: 12px;position: absolute;right: 10px;}
.chat_container .actual_chat .chat_user .chat_user_name {color: #fff;font-size: 14px;margin-bottom: 3px;font-weight: 700;}
.chat_container .actual_chat .chat_user .block_user_btn {cursor: pointer;margin-left: auto;position: relative;}
.chat_container .actual_chat .chat_user .block_user_btn span {background-color: #f00;color: #fff;font-size: 12px;font-weight: 700;padding: 3px 4px;position: absolute;left: -129px;top: -2px;
    display: none;
    border-radius: 3px;
    }
.chat_container .actual_chat .chat_user .block_user_btn:hover span {display: block;}
.chat_container .actual_chat .chat_user .block_user_btn svg {fill: #a8a8a8;width: 18px;height: 18px;}
.chat_container .actual_chat .chat_user .block_user_btn:hover svg, .chat_container .actual_chat .chat_user .block_user_btn.active svg {fill: #f00;}
.chat_container .actual_chat .chat_user .block_user_btn.active span {background-color: #089db1;}
.chat_container .actual_chat .conversation {background-color: #4a4a4a;padding: 10px;overflow-y: scroll;height: calc(100vh - 225px);
    display: flex;
    flex-direction: column-reverse;
    background-color: #1f1f1f;
    opacity: 1;
    background-image:  linear-gradient(30deg, #272727 12%, transparent 12.5%, transparent 87%, #272727 87.5%, #272727), linear-gradient(150deg, #272727 12%, transparent 12.5%, transparent 87%, #272727 87.5%, #272727), linear-gradient(30deg, #272727 12%, transparent 12.5%, transparent 87%, #272727 87.5%, #272727), linear-gradient(150deg, #272727 12%, transparent 12.5%, transparent 87%, #272727 87.5%, #272727), linear-gradient(60deg, #27272777 25%, transparent 25.5%, transparent 75%, #27272777 75%, #27272777), linear-gradient(60deg, #27272777 25%, transparent 25.5%, transparent 75%, #27272777 75%, #27272777);
    background-size: 18px 32px;
    background-position: 0 0, 0 0, 9px 16px, 9px 16px, 0 0, 9px 16px;
}
.chat_container .actual_chat .conversation .sender_balloon, .chat_container .actual_chat .conversation .receiver_balloon {color: #fff;font-size: 14px;max-width: 90%;position: relative;
    -webkit-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
}
.chat_container .actual_chat .conversation .receiver_balloon {background-color: #373737;margin: 3px auto 3px 10px;padding: 5px 35px 5px 10px;
    -webkit-border-radius: 10px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: 10px;
    -moz-border-radius-topleft: 0;
    border-radius: 10px;
    border-top-left-radius: 0;
}
.chat_container .actual_chat .conversation .receiver_balloon::before {content: '';position: absolute;left: -8px;top: 0;
    width: 0;
    height: 0;
    border-right: 4px solid #373737;
    border-top: 4px solid #373737;
    border-left: 4px solid transparent;
    border-bottom: 4px solid transparent;
}
.chat_container .actual_chat .conversation .sender_balloon {background-color: #1e7203;margin: 3px 10px 3px auto;padding: 5px 35px 5px 10px;
    -webkit-border-radius: 10px;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius: 10px;
    -moz-border-radius-topright: 0;
    border-radius: 10px;
    border-top-right-radius: 0;
}
.chat_container .actual_chat .conversation .sender_balloon::before {content: '';position: absolute;right: -8px;top: 0;
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-top: 4px solid #1e7203;
    border-left: 4px solid #1e7203;
    border-bottom: 4px solid transparent;
}
.chat_container .actual_chat .conversation .message_date {font-size: 8px;display: inline-block;position: absolute;bottom: 7px;right: 7px;}
.chat_container .actual_chat .conversation .chat_separator_info {margin: 15px 0;text-align: center;}
.chat_container .actual_chat .conversation .chat_separator_info span {background-color: #373737;color: #959595;font-size: 11px;font-weight: 700;padding: 5px 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #646464 #333;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #333;
}
*::-webkit-scrollbar-thumb {
  background-color: #646464;
  border-radius: 20px;
  border: 3px solid #333;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #8b8b8b;
  border: 3px solid #646464;
}

.conversation_text_enter {
    display: flex;
}
.conversation_text_enter .emoji_btn {cursor: pointer;width: 50px;height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.conversation_text_enter .emoji_btn svg {fill: #a0a0a0;width: 19px;height: 19px;}
.conversation_text_enter .emoji_btn:hover {background-color: #717171;}
.conversation_text_enter .emoji_btn:hover svg {fill: #fff;}
.conversation_text_enter .conversation_text {background: none;color: #fff;border: none;font-size: 14px;font-family: 'Open sans', sans-serif;line-height: inherit;max-height: 450px;min-height: 30px;margin: 10px;
    resize: none; padding: 0;
}
.chat_trigger {background-color: #f9b238;top: 50%;right: 10px;margin-top: -24px;z-index: 99;position: fixed;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 35%);
    -moz-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 35%);
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 35%);
    -webkit-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -moz-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -o-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    -ms-transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
    transition: all 0.35s cubic-bezier(.86, 0, .07, 1);
	-webkit-transform: translate3d(200%,0,0);
    transform: translate3d(200%,0,0);
}
.chat_trigger.visible {
	-webkit-transform: none;
    transform: none;
}
.chat_trigger .chats_baloon {background-color: #f03030;color: #fff;font-size: 1rem;font-weight: 700;position: absolute;right: -10px;top: -10px;width: 25px;height: 25px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
}
.chat_trigger_btn {cursor: pointer;color: #000;font-size: 1.5rem;font-family: 'Barlow condensed', sans-serif;font-weight: 700;padding: 10px;
    border-radius: 5px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.chat_trigger_btn span {display: none;text-align: center;}
.chat_trigger_btn:hover {background-color: #06c18a;}
.chat_trigger_btn svg {fill: #000;width: 28px;height: 28px}
.user_status_message {background-color: #ba0707;color: #fff;font-size: 12px;margin-left: 10px;padding: 3px 7px;border-radius: 3px;}
.chats_list_item .user_status_message {position: absolute;top: 12px;right: 50px;}
.chat_user .user_status_message {position: absolute;top: 17px;right: 50px;}

@media (min-width: 360px) {
    .chat {width: 350px;}
}

@media (min-width: 765px) {
    .chat {position: fixed;right: 20px;width: 350px; bottom: 30px;height: auto;}
}

@media (min-width: 1240px) {
    
    .chat_trigger {margin-top: -55px;}
	.chat_trigger_btn span {display: inline-block;}
}

@media (min-width: 1580px) {
}


</style>