<template>
    <div class="wrapper">

        <section class="wall_section">
            <div class="half_inner">
                <header class="page_header"><h2 class="page_heading"><span>Muro</span></h2></header>
                
                <div class="intro_message">
                    <h2>Comparte tus experiencias en nuestra red social. Publica tus fotos, comenta las de otros nómadas, haz amistades, etc.  <div class="link" @click="()=>{emitter.emit('popUpLogin', 1); }">¡Registrate ahora!</div></h2>
                </div>
                <div class="grid grid_wrap grid_wrap_reverse grid_pad">
                    <div class="grid_col grid_col_phi_2_3">
                        <div class="grid_col_box">
                            <MyMuro :loading="loading" 
                                    :muroMode="2"
                                    :postSelectedProp="postSelected"
                                    :newCommentsProp="newCommentsProp"
                                    :posts="posts"
                                    @optpost="optPost"
                                    @likepost="(index) => likePost(posts[index], emitter)"
                                    @loadmore="getPosts"/>
                        </div>
                    </div>
                    <div class="grid_col grid_col_phi_1_3">
                        <div class="grid_col_box">
                            <div class="user_profile vertical_wall_user_profile content_block boxed_white boxed">
                                <div class="user_profile_desc" v-if="store.getters.isLogged">
                                    <div class="user_profile_header">
                                        <div class="user_profile_header_info">
                                            <div class="user_profile_photo" :title="user.username">
                                                <img :src="user.imgperfil" :alt="user.username">
                                            </div>
                                            <div class="user_profile_header_text">
                                                <h2>{{user.username}}</h2>
                                            </div>
                                        </div>
                                        <div class="overlap_color"></div>
                                        <div class="user_profile_header_picture" :style="'background-image: url('+user.imgcab+')'"></div>
                                    </div>
                                </div>
                                <div class="aside_block">
                                    <div class="aside_block_header">
                                        <h4>Otros muros</h4>
                                    </div>
                                    <div class="user_list">
                                        <div class="user_list_item" v-for="(user, index) in recomendados" :key="index">
                                            <router-link :to="'/perfil/'+user.username" class="user_list_photo" :title="user.username">
                                                <img :src="user.img" :alt="user.username">
                                            </router-link>
                                            <div class="user_list_info">
                                                <router-link :to="'/perfil/'+user.username"><h4>{{user.username}}</h4></router-link>
                                            </div>
                                            <div v-if="store.getters.isLogged" @click="followTo(user.username);" class="user_list_follow_link">{{ user.follow == 1 ? 'Dejar de seguir' : 'Seguir' }} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
    import { ref, onMounted, inject } from 'vue';
    import { useRoute } from "vue-router";
    import axios from 'axios';
    import { useStore } from 'vuex';
    import MyMuro from '@/components/MyMuro.vue';
    import { likePost, denunciarPost, eliminarPost, editPost, getWallPosts, getWallPost } from "@/composables/WallPost.js";
    import { followUser } from "@/composables/user.js";
    import { changeTitle, changeMeta } from '@/composables/htmlTags.js';

    const emitter = inject('emitter');
    const store = useStore();
    const configAxios =  {headers: {}};
    const posts = ref([]);
    const user = ref(false);
    const recomendados = ref([]);
    const loading = ref(true);
    const route = useRoute();
    const postSelected = ref({});
    const newCommentsProp = ref({});
    onMounted(() => {
        if ( route.params.post ){
            getWallPost(route.params.post, (d)=>{
                if(d){
                    changeTitle(`${d.username} en Nómadas TV: "${d.mediatext}" - Nomadas.tv`);
                    changeMeta("description", `${d.username} en Nómadas TV: "${d.mediatext}" - Nomadas.tv`);
                    postSelected.value = d;
                }
            });
        }else{
            changeTitle(`Muro - Nomadas.tv`);
            changeMeta("description", "Red social de nómadas.tv");
        }
        emitter.emit('loading'); 
        if(store.getters.isLogged){
            configAxios.headers = {"API-TOKEN": store.state.token};
            getUser();
        }
        getRecomendados();
        getPosts();
    });


    const getUser = () => {
        axios
            .get(process.env.VUE_APP_URL+'perfil', configAxios)
            .then(response => {
                var rs = response.data;
                if(rs.state == 'OK'){
                    user.value = rs.data;
                }
            })
    }

    const getPosts = () => {
        if(posts.value.length >= 5 && !store.getters.isLogged){
            setTimeout(()=>{
                emitter.emit("showpopup",  { botones: true, msg: "Para poder seguir viendo posts registrate!"});
                loading.value = false;
            }, 2000);
            return;
        }
        getWallPosts({n: posts.value.length, cm: true}, (data)=>{
            if( posts.value.length == 0 )
                emitter.emit('loaded'); 
            posts.value = posts.value.concat(data.p);
            newCommentsProp.value = data.c;
            if(data.f==1){
                loading.value = false;
            }
        });
    }

    const getRecomendados = () => {
        axios.get(process.env.VUE_APP_URL+'perfil_recomendados', configAxios)
        .then(response => {
            emitter.emit('loaded'); 
            var rs = response.data;
            if(rs.state == 'OK'){
                recomendados.value = rs.data;
            }
        })
    }
    
    const followTo = (username) => {
        followUser(store, username, emitter, ()=>{
            recomendados.value.forEach((u)=>{
                if(u.username == username){
                    u.follow = !u.follow;
                }
            });
            posts.value.forEach((p)=>{
                if(p.username == username)
                    p.follow = !p.follow;
            });
        });
    }

    const optPost = (data) => {
        if(data.action == 1){
            followTo(data.username);
        }else if(data.action == 2){
            denunciarPost(data.posturl, (b)=>{
                if(b){
                    emitter.emit("showmsg", {clase: 'info', msg: "Post denunciado", timeout:3000});
                }
            });
        }else if(data.action == 3){
            eliminarPost(data.posturl, (b)=>{
                if(b){
                    emitter.emit("showmsg", {clase: 'ok', msg: "Post eliminado con exito", timeout:3000});
                    posts.value.forEach((p, index, object)=>{
                        if(p.url == data.posturl)
                            object.splice(index, 1);
                    });
                }
            });
        }
        else if(data.action == 4){
            editPost(data.post, posts.value[data.post.index], emitter);
        }
    }

</script>

<style scoped>
</style>