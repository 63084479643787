<template>
    <div class="message_fixed" v-if="open" >
        <div class="message" :class="msg_pet.clase">
            <h3>{{msg_pet.msg}}</h3>
        </div>
    </div>
</template>

<script setup>
    import { watch, ref} from 'vue';
    const props = defineProps({
        msg_pet: {
            type: Object
        }
    });
    const open = ref(false);
    watch(() => props.msg_pet, (n) => {
        if(n){
            setTimeout(()=>{ open.value=false; }, props.msg_pet.timeout ? props.msg_pet.timeout : 4000);
            open.value=true;
        }
    });
 
</script>

<style scoped>
    .message_fixed {padding: 0;position: fixed;top: 60px;left: 0;right: 0;z-index: 9999;
        display: flex;
        justify-content: center;
        -webkit-transition: all 0.3s ease-in-out;  
        -moz-transition: all 0.3s ease-in-out; 
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out; 
        transition: all 0.3s ease-in-out;
    }
    .message_fixed.visible {top: 60px;}
    .message_fixed .message {font-weight: 400;max-width: 640px;z-index: 99999;overflow: hidden;padding: 20px 30px;

        border-radius: 7px;
        -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.4);
    }
    .message_fixed .message h3 {font-size: 21px;font-weight: 700;margin: 0;text-align: center;}
    .message_fixed .message.ok {background: #06c18a;}
    .message_fixed .message.ok h3 {color: #fff;}
    .message_fixed .message.ok h3 span svg {fill: #fff;}
    .message_fixed .message.ok h3 i {fill: #fff;}
    .message_fixed .message.ko {background: #ff5050;}
    .message_fixed .message.ko h3 {color: #fff;}
    .message_fixed .message.ko h3 span svg {fill: #fff;}
    .message_fixed .message.ko h3 i {fill: #fff;}
    .message_fixed .message.info {background: #82d0ff;}
    .message_fixed .message.info h3 {color: #074e78;}
    .message_fixed .message.info h3 span svg {fill: #b3e1fd;}
    .message_fixed .message.info h3 i {fill: #074e78;}
</style>
