<template>
    <div class="modal_wrapper centered" v-if="modeShow">
        <div class="modal_window small_window">
            <header class="modal_header">
                <h3><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.451 17.337l-2.451 2.663h-2v2h-2v2h-6v-1.293l7.06-7.06c-.214-.26-.413-.533-.599-.815l-6.461 6.461v-2.293l6.865-6.949c1.08 2.424 3.095 4.336 5.586 5.286zm11.549-9.337c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-3-3c0-1.104-.896-2-2-2s-2 .896-2 2 .896 2 2 2 2-.896 2-2z"></path></svg>{{ modeShow == 1 ? 'Login' : modeShow == 2 ? 'Registro' : 'Recuperar contraseña'}}</h3>
                <div class="close_pannel" @click="()=>{ modeShow = false; }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"></path></svg>
                </div>
            </header>
            <div class="content_block boxed boxed_white boxed_end box_pad">
                <div class="grid grid_wrap grid_pad">
                    <div class="grid_col grid_col_1_1" v-if="modeShow == 1">
                        <div class="grid_col_box">
                            <h2 class="text_xl mb10 text_grey">¡Bienvenido!, aquí puedes entrar en tu cuenta.</h2>
                            <p class="mb20">¿No tienes una cuenta?, <span @click="()=>{ modeShow = 2;}" class="link" style="text-decoration: underline;" >Crea una aquí</span></p>
                            <div class="form">
                                <form @submit.prevent="login">
                                    <div class="input_block">
                                        <input v-model="userLogin.username" type="text" placeholder="Email o username">
                                    </div>
                                    <div class="input_block parent">
                                        <span id="viewpass1" class="view_pass" @click="viewPass('viewpass1', 'password_login')">
                                            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm8.413 7c-1.837 2.878-4.897 5.5-8.413 5.5-3.465 0-6.532-2.632-8.404-5.5 1.871-2.868 4.939-5.5 8.404-5.5 3.518 0 6.579 2.624 8.413 5.5zm-8.411-4c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z" fill-rule="nonzero"/></svg>
                                        </span>
                                        <input id="password_login" v-model="userLogin.password" type="password" placeholder="Contraseña">
                                    </div>
                                    <div class="input_block">
                                        <input type="submit" placeholder="enviar" value="Enviar">
                                    </div>
                                    <div class="input_block">
                                        <div class="legal_line">
                                            <p class="legal">Todos los campos son imprescindibles</p>
                                            <div @click="()=>{ modeShow = 3;}" class="link" style="font-size: 12px;text-decoration: underline;">Olvidé mi contraseña</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="mt30" v-if="noActive">
                            <div class="message ok mb20">
                                <h3>Registrado con éxito, se te ha enviado un correo donde puedes activar tu cuenta.</h3>
                            </div>
                            <div class="message ko">
                                <h3>Para poder disfrutar de todas las ventajas de nomadas.tv debes activar la cuenta.</h3>
                            </div>
                        </div>
                    </div>
                    <div class="grid_col grid_col_1_1" v-else-if="modeShow == 2">
                        <div class="grid_col_box">
                            <h2 class="text_xl mb10 text_grey">¡Registrate aquí para poder disfrutar plenamente de Nomadas.TV!</h2>
                            <p class="mb20">¿Ya estas registrado?, <span @click="()=>{ modeShow = 1;}" class="link" style="text-decoration: underline;">Entra en nomadas.tv</span></p>
                            <div class="form">
                                <form @submit.prevent="registro">
                                    <div class="input_block">
                                        <input v-model="userRegister.email" type="email" placeholder="Email">
                                    </div>
                                    <div class="input_block">
                                        <input v-model="userRegister.username" type="text" placeholder="Username">
                                    </div>
                                    <div class="input_block parent">
                                        <span id="viewPass1" class="view_pass" @click="viewPass('viewPass1', 'pass_reg1')">
                                            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm8.413 7c-1.837 2.878-4.897 5.5-8.413 5.5-3.465 0-6.532-2.632-8.404-5.5 1.871-2.868 4.939-5.5 8.404-5.5 3.518 0 6.579 2.624 8.413 5.5zm-8.411-4c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z" fill-rule="nonzero"/></svg>
                                        </span>
                                        <input id="pass_reg1" v-model="userRegister.password" type="password" placeholder="Contraseña">
                                    </div>
                                    <div class="input_block parent">
                                        <span id="viewPass2" class="view_pass" @click="viewPass('viewPass2', 'pass_reg2')">
                                            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm8.413 7c-1.837 2.878-4.897 5.5-8.413 5.5-3.465 0-6.532-2.632-8.404-5.5 1.871-2.868 4.939-5.5 8.404-5.5 3.518 0 6.579 2.624 8.413 5.5zm-8.411-4c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z" fill-rule="nonzero"/></svg>
                                        </span>
                                        <input id="pass_reg2" v-model="userRegister.password2" type="password" placeholder="Repite la Contraseña">
                                    </div>
                                    <div class="input_block">
                                        <label class="checkbox_text"><input type="checkbox" name="inp_checkbox_legal" v-model="userRegister.politica" ><span style="font-size: 13px;line-height: 13px;vertical-align: middel;">Acepto los <router-link to="/legal" @click="()=>{modeShow = false}">términos legales</router-link></span></label>
                                    </div>
                                    <div class="input_block">
                                        <input type="submit" placeholder="enviar" value="Enviar">
                                    </div>
                                    
                                    <div class="input_block">
                                        <div class="legal_line">
                                            <p class="legal">Todos los campos son imprescindibles</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="grid_col grid_col_1_1" v-else-if="modeShow == 3">
                        <div class="grid_col_box">
                            <h2 class="text_xl mb10 text_grey">Introduce tu email para recuperar contraseña</h2>
                            <p><span @click="()=>{ modeShow = 1}" class="link" style="text-decoration: underline;">Volver a Login</span></p>
                            <div class="form">
                                <form @submit.prevent="recuperar">
                                    <div class="input_block">
                                        <input v-model="emailRecuperar" type="email" placeholder="Email">
                                    </div>
                                    <div class="input_block">
                                        <input type="submit" placeholder="enviar" value="Enviar">
                                    </div>
                                </form>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { inject, ref } from 'vue';
    import axios from 'axios';
    import { useStore } from 'vuex';
    import { viewPass } from '@/composables/varios.js';

    const emitter = inject('emitter');
    const store = useStore();

    const modeShow = ref(false);

    const userLogin = {
        username: "",
        password: ""
    }

    const userRegister = {
        username: null,
        email: null,
        password: null,
        password2: null,
        politica: null
    }


    const emailRecuperar = ref("");

    let msg_pet = false;

    const noActive = ref(false);

    const login = () => {
        if(!userLogin.username || !userLogin.password){
            msg_pet = {
                clase: 'ko',
                msg: "Rellena los datos antes de enviar"
            };
            emitter.emit("showmsg", msg_pet);
            return;
        }
        axios
        .post(process.env.VUE_APP_URL+'login', {
            username: userLogin.username,
            password: userLogin.password
        })
        .then(response => {
            if(!response)
                return;
            var rs = response.data;
            if(rs.state == 'OK'){
                if(rs.username == -1){
                    noActive.value = true;
                }
                else{
                    modeShow.value = false;
                    msg_pet = {
                        clase: 'ok',
                        msg: "logeado con exito"
                    };
                    emitter.emit("showmsg", msg_pet);
                    store.commit("login", rs);
                }
            }
            else{
                msg_pet = {
                    clase: 'ko',
                    msg: rs.msg
                };
                emitter.emit("showmsg", msg_pet);
            }
        })
    }

    const registro = () => {
        if(!userRegister.username || !userRegister.email || !userRegister.password || !userRegister.password2 || (userRegister.password !== userRegister.password2)){
            var msg;
            if(userRegister.password !== userRegister.password2) msg = "Las contraseñas no coinciden";
            else msg = 'Te faltan campos por rellenar';
            msg_pet = {
                clase: 'ko',
                msg: msg,
                opacity: 1
            };
            emitter.emit("showmsg", msg_pet);
            return;
        }
        if(!userRegister.politica ){
            emitter.emit("showmsg", {clase: 'ko', msg: "No has aceptado los terminos legales"});
            return;
        }
        axios
        .post(process.env.VUE_APP_URL+'register', {
            username: userRegister.username,
            email: userRegister.email,
            password: userRegister.password,
            password_confirmation: userRegister.password2
        })
        .then(response => {
            let rs = response.data;
            if(rs.state == 'OK'){
                msg_pet = {
                    clase: 'ok',
                    msg: 'Usuario registrado con exito',
                    opacity: 1
                };
                modeShow.value = false;
            }
            else{
                msg_pet = {
                    clase: 'ko',
                    msg: rs.msg,
                    opacity: 1
                };
            }
            emitter.emit("showmsg", msg_pet);
        });
    }

    const recuperar = () => {
        if(!emailRecuperar.value || emailRecuperar.value == ""){
            msg_pet = {
                clase: 'ko',
                msg: "Introduce tu email"
            };
            emitter.emit("showmsg", msg_pet);
            return;
        }
        axios
            .post(process.env.VUE_APP_URL+'recovery', {
                email: emailRecuperar.value
            })
            .then(response => {
                var rs = response.data;
                if(rs.state == 'OK' || rs.state == "KO"){
                    msg_pet = {
                        clase: rs.state.toLowerCase(),
                        msg: rs.msg,
                        opacity: 1
                    };
                    emitter.emit("showmsg", msg_pet);
                }
            })
        }
    
    const showModal = (mode) => {
        modeShow.value = mode;
    }

    defineExpose({showModal});
 
</script>

<style scoped>

</style>
