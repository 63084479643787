<template>
    <div>
        <div class="popup_wrapper" v-if="confirmDelete">
            <div class="popup_window">
                <div class="popup_content">
                    <header class="page_header page_header_flex">
                        <h3 class="text_s">Aviso</h3>
                    </header>
                    <div class="message ko mb20">
                        <div>
                            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 8c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" fill-rule="nonzero"/></svg>
                        </div>
                        <div>
                            <h3 class="text_s">¿Estas seguro de querer borrar el post?</h3>
                        </div>
                    </div>
                    <div class="btn_wrapper right_dir">
                        <div class="btn_block">
                            <button class="btn wide med grey graphic_right" @click="optPost(4, confirmDelete)">Aceptar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z"/></svg></button>
                        </div>
                        <div class="btn_block">
                            <button class="btn wide med grey graphic_right" @click="() => {confirmDelete = false;}">Cancelar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup_wrapper" v-if="editPost">
            <div class="popup_window">
                <div class="popup_content">
                    <header class="page_header page_header_flex">
                        <h3 class="text_m_l">Editar post</h3>
                        <button class="btn med red graphic_right" @click="() => {editPost = false}">Cerrar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"/></svg></button>
                    </header>

                    <h4 class="input_label description_field_label">
                        <span>Texto del post <b>(500 caracteres máximo)</b></span>
                        <span class="character_counter"><b :class="mb_length(editPostText) > MAX_DESCR_POST ? 'ko' : 'ok'">{{ mb_length(editPostText) }}</b> / 500</span>   
                    </h4>
                    <textarea v-model="editPostText" id="editPostSelected" class="mb10" style="min-width: 300px;min-height: 240px;"></textarea>
                    <button @click="optPost(5)" class="btn med wide grey graphic_right">Guardar post<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.003 3h2.997v5h-2.997v-5zm8.997 1v20h-24v-24h20l4 4zm-19 5h14v-7h-14v7zm16 4h-18v9h18v-9z"></path></svg></button>
                </div>
            </div>
        </div>
        <div v-if="postSelected" class="modal_wrapper">
            <div @click="()=>{postSelected=false; if(route.name == 'muro') router.push('/muro');}" class="close_button">
                <div class="btn small transparent graphic_right">Cerrar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg></div>
            </div>
            <div v-if="muroMode==1 && indexPostSelected !== false" @click="selectPost(indexPostSelected -1)" class="modal_nav left_nav">
                <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.012 2c5.518 0 9.997 4.48 9.997 9.998 0 5.517-4.479 9.997-9.997 9.997s-9.998-4.48-9.998-9.997c0-5.518 4.48-9.998 9.998-9.998zm-1.523 6.21s-1.502 1.505-3.255 3.259c-.147.147-.22.339-.22.531s.073.383.22.53c1.753 1.754 3.254 3.258 3.254 3.258.145.145.335.217.526.217.192-.001.384-.074.531-.221.292-.293.294-.766.003-1.057l-1.977-1.977h6.693c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-6.693l1.978-1.979c.29-.289.287-.762-.006-1.054-.147-.147-.339-.221-.53-.222-.19 0-.38.071-.524.215z" fill-rule="nonzero"/></svg>
            </div>
            <div v-if="muroMode==1 && indexPostSelected !== false" @click="selectPost(indexPostSelected +1)" class="modal_nav right_nav">
                <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12.007 2c-5.518 0-9.998 4.48-9.998 9.998 0 5.517 4.48 9.997 9.998 9.997s9.998-4.48 9.998-9.997c0-5.518-4.48-9.998-9.998-9.998zm1.523 6.21s1.502 1.505 3.255 3.259c.147.147.22.339.22.531s-.073.383-.22.53c-1.753 1.754-3.254 3.258-3.254 3.258-.145.145-.335.217-.526.217-.192-.001-.384-.074-.531-.221-.292-.293-.294-.766-.003-1.057l1.977-1.977h-6.693c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6.693l-1.978-1.979c-.29-.289-.287-.762.006-1.054.147-.147.339-.221.53-.222.19 0 .38.071.524.215z" fill-rule="nonzero"/></svg>
            </div>
            <div class="modal_window">
                <div class="content_block boxed boxed_white">
                    <div class="post_window">
                        <div class="post_wrapper">
                            <div class="post_item_content">
                                <div class="post_window_info_header">
                                    <div class="wall_item_user_photo">
                                        <router-link :to="'/perfil/'+postSelected.username" :title="postSelected.username">
                                            <img :src="postSelected.userimg" :alt="postSelected.username" width="34" height="34">
                                        </router-link>
                                    </div>
                                    <div class="wall_item_user">
                                        <router-link :to="'/perfil/'+postSelected.username">{{postSelected.username}}</router-link> <span>| {{postSelected.date}}</span>
                                    </div>
                                    <div v-if="store.getters.isLogged" v-click-outside="()=>{ if(menuDisplay === -2){menuDisplay = false} }" class="wall_item_menu">
                                        <div @click="menuOption(-2)" class="wall_item_menu_trigger"><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z"/></svg></div>
                                        <div v-if="menuDisplay === -2" class="wall_item_menu_list">
                                            <template v-if="!postSelected.self">
                                                <div @click="optPost(1, postSelected)" v-if="postSelected.follow == 1 || postSelected.follow == 0" class="wall_item_menu_btn">{{ postSelected.follow == 1 ? 'Dejar de seguir' : 'Seguir' }}</div>
                                                <div @click="optPost(2, postSelected)" class="wall_item_menu_btn">Denunciar post</div>
                                            </template>
                                            <template v-if="postSelected.self">
                                                <div @click="optPost(3, postSelected)" class="wall_item_menu_btn">Eliminar post</div>
                                                <div @click="()=>{ editPost = postSelected; editPost.index = indexPostSelected; editPostText = postSelected.mediatext }" class="wall_item_menu_btn">Editar post</div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="post_item_media">
                                    <img :src="postSelected.media">
                                </div>  
                            </div>
                            <div class="post_window_info">
                                <div class="post_window_info_header">
                                    <div class="wall_item_user_photo">
                                        <router-link :to="'/perfil/'+postSelected.username" :title="postSelected.username">
                                            <img :src="postSelected.userimg" :alt="postSelected.username" width="34" height="34">
                                        </router-link>
                                    </div>
                                    <div class="wall_item_user">
                                        <router-link :to="'/perfil/'+postSelected.username">{{postSelected.username}}</router-link> <span>| {{postSelected.date}}</span>
                                    </div>
                                    <div v-if="store.getters.isLogged" v-click-outside="()=>{ if(menuDisplay === -1){menuDisplay = false} }" class="wall_item_menu">
                                        <div @click="menuOption(-1)" class="wall_item_menu_trigger"><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z"/></svg></div>
                                        <div v-if="menuDisplay === -1" class="wall_item_menu_list">
                                            <template v-if="!postSelected.self">
                                                <div @click="optPost(1, postSelected)" v-if="postSelected.follow == 1 || postSelected.follow == 0" class="wall_item_menu_btn">{{ postSelected.follow == 1 ? 'Dejar de seguir' : 'Seguir' }}</div>
                                                <div @click="optPost(2, postSelected)" class="wall_item_menu_btn">Denunciar post</div>
                                            </template>
                                            <template v-if="postSelected.self">
                                                <div @click="optPost(3, postSelected)" class="wall_item_menu_btn">Eliminar post</div>
                                                <div @click="()=>{ editPost = postSelected; editPost.index = indexPostSelected; editPostText = postSelected.mediatext }" class="wall_item_menu_btn">Editar post</div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="post_window_info_text">
                                    <PostComment :post="postSelected" 
                                                 :comentarios="postComments[postSelected.url]" 
                                                 @likePost="likePost(indexPostSelected)" 
                                                 @likeComentario="(c)=> { likeComment(c, postSelected.url) }"
                                                 @comment="(d)=>{  newComent(d, postSelected.url); }"
                                                 @deleteComment="(idc)=>{ delComment(idc, postSelected.url); }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="muroMode==1" class="grid_wall grid__motor grid__motor_col_3">
            <div class="grid_wall_item" v-for="post,index in posts" :key="index" @click="selectPost(index)" >
                <div class="grid_wall_item_img">
                    <div class="grid_wall_item_edit" v-if="post.self">
                        <div @click.stop="()=>{ editPost = post; editPost.index = index; editPostText =  post.mediatext; }" class="grid_wall_item_btn edit" title="Editar post"><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.25 6c.398 0 .75.352.75.75 0 .414-.336.75-.75.75-1.505 0-7.75 0-7.75 0v12h17v-8.75c0-.414.336-.75.75-.75s.75.336.75.75v9.25c0 .621-.522 1-1 1h-18c-.48 0-1-.379-1-1v-13c0-.481.38-1 1-1zm-2.011 6.526c-1.045 3.003-1.238 3.45-1.238 3.84 0 .441.385.626.627.626.272 0 1.108-.301 3.829-1.249zm.888-.889 3.22 3.22 8.408-8.4c.163-.163.245-.377.245-.592 0-.213-.082-.427-.245-.591-.58-.578-1.458-1.457-2.039-2.036-.163-.163-.377-.245-.591-.245-.213 0-.428.082-.592.245z" fill-rule="nonzero"/></svg></div>
                        <div @click.stop="optPost(3, post)" class="grid_wall_item_btn delete" title="Eliminar post"><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m20.015 6.506h-16v14.423c0 .591.448 1.071 1 1.071h14c.552 0 1-.48 1-1.071 0-3.905 0-14.423 0-14.423zm-5.75 2.494c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-4.5 0c.414 0 .75.336.75.75v8.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.5c0-.414.336-.75.75-.75zm-.75-5v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-16.507c-.413 0-.747-.335-.747-.747s.334-.747.747-.747zm4.5 0v-.5h-3v.5z" fill-rule="nonzero"/></svg></div>
                    </div>
                    <img :src="post.media">
                </div>
            </div>
        </div>


        <div v-if="muroMode==2" class="vertical_wall" @scroll="onScroll">
            <div class="wall_item" v-for="post,index in posts" :key="index">
                <div class="wall_item_header">
                    <div class="wall_item_user_photo">
                        <router-link :to="'/perfil/'+post.username" :title="post.username">
                            <img :src="post.userimg" :alt="post.username" width="34" height="34">
                        </router-link>
                    </div>
                    <div class="wall_item_user">
                        <router-link :to="'/perfil/'+post.username">{{post.username}}</router-link> <span>| {{post.date}}</span>
                    </div>
                    <div v-if="store.getters.isLogged" v-click-outside="()=>{ if(menuDisplay == index){menuDisplay = false} }" class="wall_item_menu">
                        <div @click="menuOption(index)" class="wall_item_menu_trigger"><svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z"/></svg></div>
                        <div v-if="menuDisplay === index" class="wall_item_menu_list">
                            <template v-if="!post.self">
                                <div @click="optPost(1, post)" v-if="post.follow == 1 || post.follow == 0" class="wall_item_menu_btn">{{ post.follow == 1 ? 'Dejar de seguir' : 'Seguir' }}</div>
                                <div v-if="!post.self" @click="optPost(2, post)" class="wall_item_menu_btn">Denunciar post</div>
                            </template>
                            <template v-if="post.self">
                                <div v-if="post.self" @click="optPost(3, post)" class="wall_item_menu_btn">Eliminar post</div>
                                <div @click="()=>{ editPost = post; editPost.index = index; editPostText =  post.mediatext }" class="wall_item_menu_btn">Editar post</div>
                             </template>
                        </div>
                    </div>
                </div>
                <div class="wall_item_content">
                    <img :src="post.media" alt="Post no disponible">
                </div>
                <div class="wall_item_text">
                    <div class="wall_item_actions">
                        <div class="wall_item_like">
                            <div @click="likePost(index)" class="wall_item_like_toggle">
                                <span v-if="post.islike && post.islike == true" class="like_full">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 22h-5v-12h5v12zm17.615-8.412c-.857-.115-.578-.734.031-.922.521-.16 1.354-.5 1.354-1.51 0-.672-.5-1.562-2.271-1.49-1.228.05-3.666-.198-4.979-.885.906-3.656.688-8.781-1.688-8.781-1.594 0-1.896 1.807-2.375 3.469-1.221 4.242-3.312 6.017-5.687 6.885v10.878c4.382.701 6.345 2.768 10.505 2.768 3.198 0 4.852-1.735 4.852-2.666 0-.335-.272-.573-.96-.626-.811-.062-.734-.812.031-.953 1.268-.234 1.826-.914 1.826-1.543 0-.529-.396-1.022-1.098-1.181-.837-.189-.664-.757.031-.812 1.133-.09 1.688-.764 1.688-1.41 0-.565-.424-1.109-1.26-1.221z"></path></svg>
                                </span>
                                <span v-else class="like_empty">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.406 9.558c-1.21-.051-2.87-.278-3.977-.744.809-3.283 1.253-8.814-2.196-8.814-1.861 0-2.351 1.668-2.833 3.329-1.548 5.336-3.946 6.816-6.4 7.401v-.73h-6v12h6v-.904c2.378.228 4.119.864 6.169 1.746 1.257.541 3.053 1.158 5.336 1.158 2.538 0 4.295-.997 5.009-3.686.5-1.877 1.486-7.25 1.486-8.25 0-1.648-1.168-2.446-2.594-2.506zm-17.406 10.442h-2v-8h2v8zm15.896-5.583s.201.01 1.069-.027c1.082-.046 1.051 1.469.004 1.563l-1.761.099c-.734.094-.656 1.203.141 1.172 0 0 .686-.017 1.143-.041 1.068-.056 1.016 1.429.04 1.551-.424.053-1.745.115-1.745.115-.811.072-.706 1.235.109 1.141l.771-.031c.822-.074 1.003.825-.292 1.661-1.567.881-4.685.131-6.416-.614-2.239-.965-4.438-1.934-6.959-2.006v-6c3.264-.749 6.328-2.254 8.321-9.113.898-3.092 1.679-1.931 1.679.574 0 2.071-.49 3.786-.921 5.533 1.061.543 3.371 1.402 6.12 1.556 1.055.059 1.024 1.455-.051 1.584l-1.394.167s-.608 1.111.142 1.116z"/></svg>
                                </span>
                                <b>{{post.likes}}</b>
                            </div>
                        </div>
                    </div>
                    <TextPost :text="post.mediatext" :username="post.username"/>
                    <router-link  @click="selectPost(index, true)" :to="{name: 'muro', params: {post: props.posts[index].url}}" class="post_comments_link mt10 mb10">Ver <span>{{postCommentsCount[post.url] ? postCommentsCount[post.url] : post.comentarios }}</span> comentarios</router-link>
                    <div class="post_comment_field" v-if="store.getters.isLogged">
                        <input :id="`comentar_post_${index}`"  type="text" placeholder="Añade un comentario" @keyup.enter="() => { newComentOut(index); }">
                    </div>
                </div>
            </div>
            <div class="wall_loader" v-if="props.loading">
                <div class="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, onUnmounted, watch, inject } from 'vue';
    import { useRouter, useRoute } from "vue-router";
    import { useStore } from 'vuex';
    import TextPost from '@/components/TextPost.vue';
    import PostComment from '@/components/PostComment.vue';
    import { mb_length } from '@/composables/varios.js';
    import { loadComentarios, sendComentario, likeComentario, deleteComment, MAX_DESCR_POST } from "@/composables/WallPost.js";
    import { changeTitle, changeMeta } from '@/composables/htmlTags.js';

    const store = useStore();
    const postSelected = ref(false);
    const postSelectedComments = ref([]);
    const postComments = ref({});
    const postCommentsCount = ref({});
    const menuDisplay = ref(false);
    let indexPostSelected = ref(false);
    let lastNumPosts = 0;
    const confirmDelete = ref(false);
    const editPost = ref(false);
    const editPostText = ref(false);
    const route = useRoute();
    const router = useRouter();
        
    const emitter = inject('emitter');

    const props = defineProps({
        muroMode: {
            type: Number,
            default: 1
        },
        posts:{
            type: Array,
            default() {
                return []
            }
        },
        loading:{
            type: Boolean,
            default: false
        },
        postSelectedProp:{
            type: Object,
            default() {
                return []
            }
        },
        newCommentsProp:{
            type: Object,
            default() {
                return []
            }
        },
    });

    watch(() => props.postSelectedProp, (post) => {
        if(post.url){
            postComments.value[post.url] = post.comments;
            postCommentsCount.value[post.url] = getCommentsCount(post.url);
            postSelected.value = post;
        }
    });

    watch(() => props.newCommentsProp, (comments) => {
        Object.keys(comments).forEach(url => {
            postComments.value[url] = comments[url];
            postCommentsCount.value[url] = getCommentsCount(url);
        });
    });

    onMounted(() => { 
        window.addEventListener('scroll', onScroll); lastNumPosts = props.posts.length; 
    });
    onUnmounted(()=>{  window.removeEventListener('scroll', onScroll); });

    const emit = defineEmits(['likepost', 'optpost', "loadmore"])

    const getCommentsCount = (url) => {
        let sum = 0;
        postComments.value[url].forEach((c)=>{
            sum+= 1 + c.items.length;
        });
        return sum;
    }

    const selectPost = (id, meta=false) => {
        menuDisplay.value = false;
        if(id >= 0 && id < props.posts.length){
            if(postComments.value[props.posts[id].url]){
                indexPostSelected.value = id;
                postSelected.value = props.posts[id];
                postSelectedComments.value = postComments.value[props.posts[id].url];
                if(meta){
                    changeTitle(`${props.posts[id].username} en Nómadas TV: "${props.posts[id].mediatext}" - Nomadas.tv`);
                    changeMeta("description", `${props.posts[id].username} en Nómadas TV: "${props.posts[id].mediatext}" - Nomadas.tv`);
                }
            }else{
                loadComentarios(props.posts[id].url, (comentarios)=>{
                    indexPostSelected.value = id;
                    postSelected.value = props.posts[id];
                    postComments.value[props.posts[id].url] = comentarios;
                    postCommentsCount.value[props.posts[id].url] = getCommentsCount(props.posts[id].url);
                    postSelectedComments.value = postComments.value[props.posts[id].url];
                });
            }
        }
    }

    const delComment = (idc, url) => {
        deleteComment(idc.idc, ()=>{
            removeComment(idc, url);
        });
    }

    const removeComment = (idc, url) => {
        let index;
        if(idc.idp){
            let indexParent = postComments.value[url].map(function(e) { return e.id; }).indexOf(idc.idp);
            if(indexParent == -1)
                return;
            index = postComments.value[url][indexParent].items.map(function(e) { return e.id; }).indexOf(idc.idc);
            if (index > -1)
                 postComments.value[url][indexParent].items.splice(index, 1);
        }else{
            index = postComments.value[url].map(function(e) { return e.id; }).indexOf(idc.idc);
            if (index > -1)
                postComments.value[url].splice(index, 1);
        }
    }

    const newComentOut = (index) => {
        let input = document.getElementById(`comentar_post_${index}`);
        let d = {c: input.value, p: 0, u: props.posts[index].url};
        sendComentario(d, emitter, (id)=>{
            if(id && postComments.value[props.posts[index].url]){
                postComments.value[props.posts[index].url].unshift(newCommentObject(d, id));
                postCommentsCount.value[props.posts[index].url]++;
            }
        });
        input.value = "";
    }

    const newComent = (d, url) => {
        sendComentario(d, emitter, (id) => {
            if(id){
                if(d.p == 0){
                    postComments.value[url].unshift(newCommentObject(d, id));
                }else{
                    for(let i=0; i < postComments.value[url].length; i++){
                        if(postComments.value[url][i].id == d.p){
                            postComments.value[url][i].items.unshift(newCommentObject(d, id));
                        }
                    }
                }
                postCommentsCount.value[url]++;
            }
        });
    }

    const newCommentObject = (d, id) => {
        return {id: id, comentario: d.c, username: store.state.user_data.u, userimg: store.state.user_data.i, items:[], islike: 0, likes:0};
    }

    const likeComment = (c, url) => {
        likeComentario(c, emitter,  (r) => {
            if(r){
                for(let i=0; i < postComments.value[url].length; i++){
                    if(postComments.value[url][i].id == c){
                        setVisualLike(postComments.value[url][i]);
                    }
                    for(let j=0; j < postComments.value[url][i].items.length; j++){
                        if(postComments.value[url][i].items[j].id == c){
                            setVisualLike(postComments.value[url][i].items[j]);
                        }
                    }
                }
        }
        });
    }

    const setVisualLike = (c) => {
        if(c.islike){
            c.islike = false;
            c.likes--;
        }else{
            c.islike = true;
            c.likes++;
        }
    }

    const likePost = (index) => {
        emit("likepost", index);
    }
    const optPost = (opt, post) => {
        let data;
        if(opt == 1)
            data = {"action":1, "follow": !post.follow, "username":post.username};
        else if(opt == 2)
            data = {"action":2, "posturl":post.url};
        else if(opt == 3){
            confirmDelete.value = post.url;
            menuDisplay.value = false; 
            postSelected.value = false;
            return;
        }
        else if(opt == 4){
            data = {"action":3, "posturl":post};
            confirmDelete.value = false;
        }
        else if(opt == 5){
            data = {"action":4, post: {"descripcion": editPostText.value, "url": editPost.value.url, "index": editPost.value.index}};
            editPost.value = false;
        }
        emit("optpost", data);
        menuDisplay.value = false; 
    }
    
    const menuOption = (index) => {
        if(menuDisplay.value === index){
            menuDisplay.value = false; 
        }
        else {
            menuDisplay.value = index
        }
    }
    
    const onScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 100) {
            if(lastNumPosts < props.posts.length ){
                lastNumPosts = props.posts.length;
                emit("loadmore");
            }
        }
    }
    defineExpose({selectPost});

</script>

<style scoped>

/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

WALL

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*----------------------------------------------------------------------------------- 
GRID WALL
-----------------------------------------------------------------------------------*/
.grid_wall {background-color: #fff;position: inherit;padding: 10px;
    border-radius: 5px;
}
.grid_wall .grid_wall_item {background-color: #000;cursor: pointer;position: relative;overflow: hidden;;
    border-radius: 7px;
}
.grid_wall .grid_wall_item .grid_wall_item_img {padding-top: 100%;overflow: hidden;}
.grid_wall .grid_wall_item .grid_wall_item_img img {width: 100%;height: 100%;position: absolute;top: 0;right: 0;bottom: 0;left: 0;object-fit: cover;z-index: 1;
     opacity: 1;
}
.grid_wall .grid_wall_item .grid_wall_item_img .grid_wall_item_edit {background-color: rgba(0,0,0,0.3);position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 2;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.grid_wall .grid_wall_item:hover .grid_wall_item_img .grid_wall_item_edit {display: flex;}
.grid_wall .grid_wall_item .grid_wall_item_img .grid_wall_item_edit .grid_wall_item_btn {width: 30px;height: 30px;
    opacity: 0.7;
}
.grid_wall .grid_wall_item .grid_wall_item_img .grid_wall_item_edit .grid_wall_item_btn:hover {
    opacity: 1;
}
.grid_wall .grid_wall_item .grid_wall_item_img .grid_wall_item_edit .grid_wall_item_btn svg {fill: #fff;}
.grid_wall.grid__motor.grid__motor_col_1, .grid_wall.grid__motor.grid__motor_col_2, .grid_wall.grid__motor.grid__motor_col_3, .grid_wall.grid__motor.grid__motor_col_4, .grid_wall.grid__motor.grid__motor_col_5 {
    grid-gap: 5px 5px;
}

/*----------------------------------------------------------------------------------- 
VERTICAL WALL
-----------------------------------------------------------------------------------*/
.vertical_wall {width: 100%}
.vertical_wall .wall_item {background-color: #fff;margin-top: 30px;
	border-radius: 7px;
    -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
	-moz-box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
	box-shadow: 0 2px 3px 0 rgba(0,0,0,.2);
}
.vertical_wall .wall_item:first-child {margin-top: 0;}
.vertical_wall .wall_item .wall_item_header {padding: 10px;
	display: flex;
	align-items: center;
}
.vertical_wall .wall_item .wall_item_header .wall_item_user_photo {width: 35px;margin-right: 10px;}
.vertical_wall .wall_item .wall_item_header .wall_item_user_photo img {border: solid 1px #cacaca;width: 35px;height: 35px;
	border-radius: 30px;
}
.vertical_wall .wall_item .wall_item_header .wall_item_user_photo:hover img {border-color: #333;}
.vertical_wall .wall_item .wall_item_header .wall_item_user {font-size: 13px;font-family: 'Open sans', sans-serif;}
.vertical_wall .wall_item .wall_item_header .wall_item_user a {font-weight: bold;}
.vertical_wall .wall_item .wall_item_header .wall_item_user span {color: #989898;}
.vertical_wall .wall_item .wall_item_menu {cursor: pointer;margin-left: auto;position: relative;width: 30px;height: 30px;
	display: flex;
}
.vertical_wall .wall_item .wall_item_menu_trigger{width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vertical_wall .wall_item .wall_item_menu_trigger svg {width: 18px;height: 18px;} 
.vertical_wall .wall_item .wall_item_menu_trigger:hover, .vertical_wall .wall_item .wall_item_menu_trigger.active, .vertical_wall .wall_item .wall_item_menu_trigger.active  {background-color: #e5e5e5;
	border-radius: 30px;
} 
.wall_item_menu_list {background-color: #fff;position: absolute;top: 30px;right: 5px;width: 120px;z-index: 10;overflow: hidden;
	border-radius: 5px;
	-webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.70);
	-moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.70);
	box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.70);
}
.wall_item_menu_btn {border-top: solid 1px #e7e7e7;cursor: pointer;font-size:13px;padding:5px 10px;text-align: center;}
.wall_item_menu_btn::first-child {border: none;}
.wall_item_menu_btn:hover {background-color: #e5e5e5;}
.wall_item_text {padding: 15px;}
.wall_item_text p {margin-bottom: 0;}
.wall_item_actions {margin-bottom: 20px;}
.post_comments_link  {cursor: pointer;font-size: 12px;margin: 10px  0;}
.post_comments_link:hover  {text-decoration: underline;}
.post_comment_field input {border-width: 0 0 2px 0;font-size: 12px;padding: 10px 0;
    border-radius: 0;
}
.wall_item_like {
	display: flex;
}
.wall_item_like .wall_item_like_toggle {cursor: pointer;position: relative;height: 16px;overflow: hidden;padding-left: 25px;}
.wall_item_like .wall_item_like_toggle span {position: absolute;left: 0;top: 0;}
.wall_item_like .wall_item_like_toggle b {font-size: 16px;line-height: 16px;}
.wall_item_like .wall_item_like_toggle span svg {fill: #6b6b6b;width: 16px;height: 16px;}
.wall_item_like .wall_item_like_toggle:hover span svg {fill: #000;}
.wall_item_like .wall_item_like_toggle span.like_empty {visibility: visible;z-index: 2;}
.wall_item_like .wall_item_like_toggle span.like_full {visibility: visible;z-index: 1;}

/*----------------------------------------------------------------------------------- 
POST WINDOW
-----------------------------------------------------------------------------------*/
.post_window {width: 100%}
.post_wrapper {
    display: flex;
    flex-direction: column;
}
.post_window .post_item_content {background-color: #000;max-width: 900px;min-height: 400px;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    flex-shrink: 1;
    justify-content: center;
}
.post_window .post_item_content .post_window_info_header {background-color: #fff;border-bottom: solid 1px #e5e5e5;padding: 10px;
    display: flex;
    align-items: center;
}
.post_window .post_item_content .post_window_info_header .wall_item_user {font-size: 12px;}
.post_window .post_item_content .post_window_info_header .wall_item_user a {font-weight: 700;}
.post_window .post_item_content .post_window_info_header .wall_item_user span {color: #989898;}
.post_window .post_item_content img {height: 100%;}
.user_awards {
	display: flex;
	gap: 5px;
	flex-direction: row;
}
.post_window .post_window_info {background-color: #fff;min-height: 150px;
	border-radius: 7px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 2;
    align-items: stretch;
}
.post_window .wall_item:first-child {margin-top: 0;}
.post_window .post_window_info .wall_item_user_photo {width: 35px;margin-right: 10px;}
.post_window .post_window_info .wall_item_user_photo img {border: solid 1px #cacaca;width: 35px;height: 35px;
	border-radius: 30px;
}
.post_window .post_window_info .wall_item_user_photo:hover img {border-color: #333;}
.post_window .post_window_info .post_window_info_header {border-bottom: solid 1px #e5e5e5;display: none;padding: 10px;
}
.post_window .post_window_info .post_window_info_header .wall_item_user {font-family: 'Open sans', sans-serif;font-size: 13px;}
.post_window .post_window_info .post_window_info_header .wall_item_user a {font-weight: bold;}
.post_window .post_window_info .post_window_info_header .wall_item_user span {color: #989898;}

.post_window .post_window_info .post_window_info_text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.post_window .post_window_info .post_window_info_text .post_user_comment {
    display: flex;
}
.post_window .post_window_info .post_guest {margin-top: 20px;
    display: flex;
}
.post_window .post_window_info .post_guest .post_guest_comment {
    display: flex;
}
.post_window .post_window_info .post_window_info_header .wall_item_user_photo:hover img {border-color: #333;}
.post_window .post_window_info .post_window_info_footer {border-top: solid 1px #e5e5e5;padding: 10px;}
.post_window .wall_item_menu {cursor: pointer;margin-left: auto;position: relative;width: 30px;height: 30px;
    display: flex;
}
.post_window .wall_item_menu_trigger{width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.post_window .wall_item_menu_trigger svg {width: 18px;height: 18px;} 
.post_window .wall_item_menu_trigger:hover, .post_window .wall_item_menu_trigger.active, .post_window .wall_item_menu_trigger.active  {background-color: #e5e5e5;
	border-radius: 30px;
}
.post_window .post_window_info_header .wall_item_user_photo {width: 35px;margin-right: 10px;}
.post_window .post_window_info_header .wall_item_user_photo img {border: solid 1px #cacaca;width: 35px;height: 35px;
	border-radius: 30px;
}

@media (min-width: 765px) {
.grid_wall {padding: 20px;}
.post_wrapper {max-height: 90vh;
    flex-direction: row;
}
.post_window .post_window_info {min-width: 400px;max-width: 500px;}
.post_window .post_window_info .post_window_info_header {
    display: flex;
    align-items: center;
}
.post_window .post_window_info .post_window_info_text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.post_window .post_item_content .post_window_info_header {
    display: none;
}

}

</style>
